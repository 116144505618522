import React from 'react'
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
//import { PieChart } from 'react-minimal-pie-chart';
import BankLogoPage from './bankLogoPage';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport,ExcelExportColumn,ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ColumnMenu } from "./columnMenu";
import { orderBy } from "@progress/kendo-data-query";
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
//import BarChart from 'react-bar-chart';
import Enumerable from 'linq';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import { where } from 'firebase/firestore';
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const FixedIncomePortfolioOverviewGrid = ({data}) => {
    
       // call active report
 // const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    
    // load report definition from the file
    
    const reportResponse = await fetch(
      "PortfolioOverviewFI.rdlx-json"
    );
    
    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    
    const report = await loadReport();
    

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);
      
    viewerRef.current.Viewer.open(report);
  }
 
  //
    const chartDefaultV4Colors = [
        "#00876c",
        "#6e9b75",
        "#a5af90",
        "#cbc6b9",
        "#c9a47e",
        "#d07958",
      ];
    
    //const [selAcct, SetselAcct] = useState((localStorage.getItem('IsAcctSelected'))?(JSON.parse(localStorage.getItem('AcctSelected'))):(JSON.parse(localStorage.getItem('acctData'))[0]));
    let selAcct=JSON.parse(localStorage.getItem('AcctSelected'));
    
    
    //const [selAcct, SetselAcct] = useState(selAcctVal);

    let table1=[];
    let table2=[];
    let table3=[];
    let table4=[];
    let table5=[];
    

    table1=Enumerable.from(data.lstFixedIncomePortfolioOverviewT1).where(w => w.acctId === selAcct.acctId).toArray();
    table2=Enumerable.from(data.lstFixedIncomePortfolioOverviewT2).where(w => w.acctId === selAcct.acctId).toArray();
    table3=Enumerable.from(data.lstFixedIncomePortfolioOverviewT3).where(w => w.acctId === selAcct.acctId).toArray();
    table4=Enumerable.from(data.lstFixedIncomePortfolioOverviewT4).where(w => w.acctId === selAcct.acctId).toArray();
    table5=Enumerable.from(data.lstFixedIncomePortfolioOverviewT5).where(w => w.acctId === selAcct.acctId).toArray();
    
    const reportData = {
        lstFixedIncomePortfolioOverviewT1: table1,
        lstFixedIncomePortfolioOverviewT2: table2,
        lstFixedIncomePortfolioOverviewT3: table3,
        lstFixedIncomePortfolioOverviewT4: table4,
        lstFixedIncomePortfolioOverviewT5: table5
    };
    
    
    
    
    const [chartType,setChartType]=useState("pie");
    const [sort, setSort] = React.useState([]);
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const RightNameHeader = (props) => {
        return (
            <a className="k-link" style={{
                float: "right",
            }} onClick={props.onClick}>
                {/* <span className="k-icon k-i-cart" /> */}
                <span
                    style={{
                        // color: "#53d2fa",
                    }}
                >
                    {props.title}
                </span>
                {props.children}
            </a>
        );
    };
    const FormatLongNumber=({value})=> {
       
        if(value == 0) {
          return 0;
        }
        else
        {
              // for testing
            //value = Math.floor(Math.random()*1001);
       
            // hundreds
            if(value <= 999){
              return value;
            }
            // thousands
            else if(value >= 1000 && value <= 999999){
              return (value / 1000) + 'K';
            }
            // millions
            else if(value >= 1000000 && value <= 999999999){
              return (value / 1000000) + 'M';
            }
            // billions
            else if(value >= 1000000000 && value <= 999999999999){
              return (value / 1000000000) + 'B';
            }
            else
              return value;
        }
      }
      const CustomGroupHeader = (props) => {
        //
        return `${props.value}`;
      };
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.rtngPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${(props.dataItem.rtngPercent*100).toFixed(2)}%`;
    };

    const labelContentMnr = (props) => {

        let formatedNumber = Number(props.dataItem.classPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${(props.dataItem.classPercent*100).toFixed(2)}%`;
    };
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
    const labelContentMjr = (props) => {

        let formatedNumber = Number(props.dataItem.sectorPct).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${(props.dataItem.sectorPct*100).toFixed(2)}%`;
    };
    const totalSum = (props) => {
        const field = props.field || "";
        const total = data.lstFixedIncomePortfolioOverviewT3 .reduce((acc, current) => acc + current[field], 0);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, "##,#.00")}
            </td>
        );
    }; 
    let allPDFExport;
    const printPDF = async () => {
      
      if (allPDFExport !== null) {
        allPDFExport.save();
      }
    };
    const _export = React.useRef(null);
    const excelExport = () => {
        setShow(true);
        openReport();
    //   if (_export.current !== null) {
    //     _export.current.save(data);
    //   }
    };
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);

    const showpreview = async () => {
        setShow(true);
      }
      const PageTemplate = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "1px",
                        width:"98%",
                        borderBottom: "1px solid #bce8f1"
                       
                    }}
                >
                   
                   <div className='row d-flex mx-3'>
                        <div className='col text-start'>
                        <a className='px-2'  ><BankLogoPage /></a>
    
                        </div>
                        <div className='col text-end px-5 py-2'>
                        <h2 className='fw-bold text-fitek'  >Fixed Income Portfolio Overview Report</h2>
    
                        </div>
    
    
                       </div>
                    
                   
                </div>
                       
                <div
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      //  borderTop: "1px solid #bce8f1"
                    }}
                >
                    Page {props.pageNum} of {props.totalPages}
                </div>
            </div>
        );
    };
    
    console.log(table3);
    let test= table3;
    
       return(
        
        <div>
               <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">

<Modal.Body>
  <div id="viewer-host">

    <Viewer ref={viewerRef}

    />
  </div>
</Modal.Body>
<Modal.Footer>



  <button className="btn btn-primary  btn-sm" onClick={handleClose}>
    Close
  </button>


</Modal.Footer>

</Modal>
            <div className='card-header row d-flex justify-content-between align-items-center my-2'>
            <div className="col">
          <p className="reportheading fs-6">Fixed Income Portfolio Overview Report</p>
        </div>
            <div className='subheader col-md text-end'>Due to rounding, percentage may not equal 100.</div>
            
            <div className="col text-end">
            <button
                  className='btn btn-outline-primary btn-sm'
                  onClick={excelExport}>Preview</button>
           </div>
            </div>

{/*             
         
           <ExcelExportColumnGroup
             title={"Account Number: "+JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId+"  Processing Date: " + localStorage.getItem("processingDate") }
             headerCellOptions={{
               textAlign: "left",
             }}
           >
              <ExcelExportColumn
                 field="tranTypNm"
                 hidden={true}
                 groupHeader={CustomGroupHeader}
               />
               
              <ExcelExportColumn field="ratingDesc" title="Description"   />
              <ExcelExportColumn field="shares" title="Par Value"    />
              
           </ExcelExportColumnGroup>
           </ExcelExport> */}


            <div className='row d-flex m-1 p-1'>

                <div className='col'>
                    <div className='card'>


                        <div className='card-header'>
                        <div className='tableheader h6'>Bond Quality Sector</div>
                            </div>
                        <div className='card-body'>

                                    <Chart seriesColors={chartDefaultV4Colors} style={{ height: "340px" }}>
                            {/* <ChartTitle text="Major Asset Chart" /> */}
                            <ChartLegend position="left" />
                            
                            <ChartSeries>
                            <ChartSeriesItem
                            type={chartType}
                            data={table2}
                            field="rtngPercent"
                            categoryField="rating"
                            autoFit={false}
                            labels={{
                                visible: true,
                            content: labelContent1,
                            }}
                                />
                            </ChartSeries>
                        </Chart>

                        </div>


                    </div>




                </div>
                </div>

                <div className='row d-flex m-1 p-1'>


                <div className='col'>
                    <div className='card'>


                        <div className='card-header'>

                        <div className='tableheader h6'>Maturity Ladder</div>


                        </div>
                        <div className='card-body'>
                            <div className='row'>

                            <div className='col'>

                            <ExcelExport data={table3}  ref={_export} fileName={JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "_AcctTransactDateaRange.xlsx"} >
           
           <Grid style={{ height: "500px" }}
              data={orderBy(table3, sort)}
             //data={table3}
               sortable={true}
               sort={sort}
               onSortChange={(e) => {
                   setSort(e.sort);
               }}
           >

               <Column field="ratingDesc" menu={true} title="Description" width="150px" />
               <Column field="shares" menu={true} title="Par Value" width="150px"  columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               {/* <Column field="avgYield" menu={true} title="Avg Yield" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               <Column field="avgYTM" menu={true} title="Avg YTM" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               <Column field="avgDuration" menu={true} title="Avg Duration" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               <Column field="avgMaturity" menu={true} title="Avg Maturity" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               <Column field="avgYTW" menu={true} title="Avg YTW" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
               <Column field="avgCalPutDuration" menu={true} title="Avg Duration(Call/Put)" width="150px" footerCell={Avg} columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} /> */}

           </Grid></ExcelExport >


                            </div>

                            <div className='col'>

                            <Chart seriesColors={chartDefaultV4Colors} style={{ height: "500px" }}>
                                        {/* <ChartTitle text="Major Asset Chart" /> */}
                                        <ChartLegend position="bottom" />

                                        <ChartValueAxis>
                                        <ChartValueAxisItem
                                            // title={{
                                            //     text: "Percentage",
                                            // }}
                                            min={0}
                                           labels={{
                                            visible: true,
                                          
                                            rotation: 20,
                                            //format: "d",
                                            content:FormatLongNumber
                                         
                                        }}
                                        />
                                    </ChartValueAxis>
                                        <ChartCategoryAxis>
                                            <ChartCategoryAxisItem
                                                labels={{
                                                    visible: true,
                                                    rotation: 45,
                                                    format: "d",
                                                }}
                                            //  categories={categoryAxis} 
                                            />
                                        </ChartCategoryAxis>
                                        <ChartTooltip render={defaultTooltipRender} />
                                        <ChartSeries>
                                            <ChartSeriesItem
                                                type="column"
                                                data={table3}
                                                field="shares"
                                                categoryField="ratingDesc"

                                                /*labels={{
                                                    visible: false,
                                                    content: labelContent,
                                                }}*/
                                            />
                                        </ChartSeries>
                                    </Chart>



                            </div>


                            </div>
                            <div className='row d-flex pt-2 p-1'>
            

            <div className='col'>
                <div className='card'>
                    <div className='card-header'>Average Yield</div>
                    <div className='card-body fw-bold'>{table3.length===0?"0.00":table3[0].avgYield}</div>


                </div>

            </div>

            <div className='col'>
                <div className='card'>
                    <div className='card-header'>Average Maturity</div>
                    <div className='card-body fw-bold'>{table3.length===0?"0.00":table3[0].avgMaturity}</div>


                </div>

            </div>

            <div className='col'>
                <div className='card'>
                    <div className='card-header'>Average YTM</div>
                    <div className='card-body fw-bold'>{table3.length===0?"0.00":table3[0].avgYTM}</div>


                </div>

            </div>

            <div className='col'>
                <div className='card'>
                    <div className='card-header'>Avg Duration (Maturity)</div>
                    <div className='card-body fw-bold'>{table3.length===0?"0.00":table3[0].avgDuration}</div>


                </div>

            </div>

            


            </div>

                            



                       
                            
                        </div>


                    </div>




                </div>

               




            </div>
               
           



                       
            
       
                  
            {/* <div className="card-body">
            <div className="mx-1 my-1 py-1">
            
           
                    {table3?
                    <div>
<div className='row'>
<div className='col card'>
  <div className='fw-bold'>
  Average Yield:

  </div>
  <div className=''>
  {table3[0].avgYield}

  </div>

</div>
</div>
<div className='row'>
<div className='col card'>
<div className='fw-bold'>
Average Maturity:

  </div>
  <div className=''>
  {table3[0].avgMaturity}

  </div>

</div>
</div>
<div className='row'>
<div className='col card'>

<div className='fw-bold'>
Average YTM:

  </div>
  <div className=''>
  {table3[0].avgYTM}

  </div>
        

</div>
</div>
<div className='row'>
<div className='col card'>
<div className='fw-bold'>
Avg Duration (Maturity):

  </div>
  <div className=''>
  {table3[0].avgDuration}

  </div>

</div>
</div>
</div>:<div className='row'><div className='col card'>
  <div className='fw-bold' style={{textAlign:'center',padding: '16px 0'}}>
  No Data Found
  </div>
  <div className='lead'>
  

  </div>

</div></div>}
                    
                     </div>
                     </div>    */}


                     


                     <div className='row d-flex m-1 p-1'>
                     <div className='col'>

                     <div className='card'>
                        <div className='card-header tableheader h6'>Bonds: By Asset Classification - Minor Class</div>
                        <div className='card-body'>
                            <div style={{ height: "400px" }}>
                        <Chart seriesColors={chartDefaultV4Colors} style={{ height: "400px" }}>
                {/* <ChartTitle text="Major Asset Chart" /> */}
                <ChartLegend position="bottom" />

                <ChartSeries>
                <ChartSeriesItem
                type={chartType}
                data={table4}
                autoFit={false}
                field="classPercent"
                categoryField="minorClass"
                labels={{
                    visible: true,
                    content: labelContentMnr,
                }}
                    />
                </ChartSeries>
            </Chart></div>
                        </div>
                        </div>
                        </div>
                        
                     <div className='col'>

                     <div className='card'>
                        <div className='card-header tableheader h6'>Bonds: By Industry Sector -  Major industry</div>
                        <div className='card-body'>
                        <Chart seriesColors={chartDefaultV4Colors} style={{ height: "400px" }}>
                {/* <ChartTitle text="Major Asset Chart" /> */}
                <ChartLegend position="bottom" />

                <ChartSeries>
                <ChartSeriesItem
                type={chartType}
                data={table5}
                field="sectorPct"
                categoryField="indSector"
                autoFit={false}
                labels={{
                    visible: true,
                    rotation:0,
                    content: labelContentMjr,
                }}
                    />
                </ChartSeries>
            </Chart>
                        </div>
                        </div>
                        </div>
                        </div>            
                    
                     
                
     
  

             
 




         
    </div>
    )
}
export default FixedIncomePortfolioOverviewGrid