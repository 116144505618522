import React from "react";
import { ImFilePdf } from "react-icons/im";
import { Designer } from "@grapecity/activereports-react";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  FaCogs,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaPrint,
  FaSave,
  FaSyncAlt,
} from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";
import { Switch } from "@progress/kendo-react-inputs";
import Loading from "./loading";
import Header from "./header";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import BankLogoPage from "./bankLogoPage";
import TestAcct from "./testAcct";
import { AiOutlineCloseCircle } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import AcctHoldingWidget from "./acctHoldingWidget";
import { AiOutlineClose } from "react-icons/ai";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import {
  formatNumber,
  formatDate,
  parseNumber,
  parseDate,
} from "@telerik/kendo-intl";
import Moment from "moment";
import { process } from "@progress/kendo-data-query";
import Enumerable from "linq";
import axios from "axios";
import { GridPDFExport, PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { ResponsiveContainer } from "recharts";
import RGL, { WidthProvider } from "react-grid-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import { Resizable, ResizableBox } from "react-resizable";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import "react-grid-layout/css/styles.css";
//import "react-resizable/css/styles.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import data from "./FifRpt.json";
import Widget from "./Widget";
import GridLayout from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import TopBar from "./Topbar";
import { withSize } from "react-sizeme";
import TimeWtdRtnRptChartWidget from "./TimeWtdRtnRptChartWidget";
import TimeWtdRtnRptGridWidget from "./TimeWtdRtnRptGridWidget";
import SctrReturnPerfrmnceDataWidget from "./sctrReturnPerfrmnceDataWidget";
import SctrReturnPerfrmnceChartWidget from "./sctrReturnPerfrmnceChartWidget";
import TopHoldingsWidget from "./topHoldingsWidget";
import AssetvsModelDataWidget from "./assetvsModelDataWidget";
import AllocationmodelWidget from "./allocationmodelWidget";
import PortfolioHoldingWidget from "./portfolioHoldingWidget";
import AssetVsModelChartWidget from "./assetVsModelChartWidget";
import TextfieldWiget from "./textfieldWiget";
import TextBoxConclusion from "./textBoxConclusion";
import CoverPageWiget from "./coverPageWiget";
import TableContentWiget from "./tableContentWiget";
import CashflowWidget from "./cashflowWidget";
import AstByMrktCapitalWidget from "./astByMrktCapitalWidget";
import AcctHoldingRptWidget from "./acctHoldingRptWidget";
import FixdIncmFundmntlsGrid from "./fixdIncmFndmntlsWidgetGrid";
import AcctperfRORWidget from "./acctperfRORWidget";
import AcctperfSummaryWidget from "./acctperfSummaryWidget";
import AcctperfChartWidget from "./acctperfChartWidget";
import { elementAcceptingRef } from "@mui/utils";
//import { Card } from 'react-bootstrap';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
//import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import AcctTransactionGridWidget from "./acctTransactionGridWidget";
import SectorComparisionGridWidget from "./sectorComparisionGridWidget";
import SectorComparisionChartWidget from "./sectorComparisionChartWidget";
import PortPerfSecurityGridWidget from "./portPerfsecurityGridWidget";
import PortfolioBreakdown1stGridWidget from "./portfolioBreakdown1stGridWidget";
import PortfolioBreakdown2ndGridWidget from "./portfolioBreakdown2ndGridWidget";
import PortfolioBreakdown3rdGridWidget from "./portfolioBreakdown3rdGridWidget";
import PortfolioSnpshotGridWidget from "./portfolioSnpshotGridWidget";
import PortfolioSnpshotCurrentChartWidget from "./portfolioSnpshotCurrentChartWidget";
import PortfolioSnpshot2ndChartWidget from "./portfolioSnpshot2ndChartWidget";
import PortfolioSnpshotBarChartWidget from "./portfolioSnpshotBarChartWidget";
import AssetAllocVsModelGridWidget from "./assetAllocVsModelGridWidget";
import PrchsSaleWidget from "./prchsSaleWidget";
import PortfoloiAllocatiionWidget from "./portfoloiAllocatiionWidget";
import ModelAllocationWidget from "./modelAllocationWidget";
import PortfolioVsModelWidget from "./portfolioVsModelWidget";
import AccountProfileSmryWidget from "./accountProfileSmryWidget";
import FimMaturityDetails from "./fimMtrtyDtlWidget";
import FimCallPutDetails from "./fimCallPutDetailsWidget";
import FimMtrDtVsClPutdtl from "./fimMtrDtVsClPutdtlChrtWidget";
import FipoBondQultySctr from "./fipoBondQultySctrWidget";
import FipoMtrtyLadder from "./fipoMtrtyLadderWidget";
import FipoMnrIndusSec from "./fipoMnrIndusSecChartWidget";
import FipoMjrIndusSec from "./fipoMjrIndusSecChartWidget";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { Viewer } from "@grapecity/activereports-react";
import DailyPerformanceWidgetGrid from "./dailyperfwidgetGrid";
var portfoliholdingsDatavar;
var acctProfFinalDatavar;
var allocmodelDatavar;
var assetVsModelFinalDatavar;
var topHoldingDatavar;
var dailyperfDatavar;
var cashflowDatavar;
var astByMrktCapitalDatavar;
var accountHoldingDatavar;
var fixdincmfndmntlsDatavar;
var perfRORDatavar;
var perfMjrAsstSummaryDatavar;
var perfChartDatavar;
var TimeWtdRtnRptChartDatavar;
var TimeWtdRtnRptGridDatavar;
var sctrReturnPerfrmnceDatavar;
var sctrReturnPerfrmnceChartDatavar;
var transactionDatavar;
var sectorCmprsnDatavar;
var portPerfBySecurityDatavar;
var portfolioBrkdwn1stDatavar;
var portfolioBrkdwn2ndDatavar;
var portfolioBrkdwn3rdDatavar;
var snapshotCmprsnDatavar;
var snapshotCmprsncolorDatavar;
var purchaseAndSaleData;
var astVsModelData;
var acctPrflSmryDatavar;
var fimMaturityDeatils;
var fimMaturityLadrCallDetails;
var fimMatrtyDtVsCallPutDt;
var fipoBondQultySctr;
var fipoMaurityLadder;
var fipoBondByMinorCls;
var fipoBondByMjrIndstry;
/////////////////////Asset growth and allocation
var AssetGrowthAlloc1;
var AssetGrowthAlloc2;
/////////////////////   Historical Monthly Performance
var HistoricalMonthlyPerformance1;
var HistoricalMonthlyPerformance2;
var HistoricalMonthlyPerformance3;
var HistoricalMonthlyPerformance4;
var HistoricalMonthlyPerformance5;
////////////////  Annual Portfolio
var AnnualPortfolio1;
var AnnualPortfolio2;
var AnnualPortfolio3;
//////////////
//var BenchmarkselectedPrioudPerformance;
////////////////////////// Performance Risk Metrics
var PerformanceRiskMetrics1;
var PerformanceRiskMetrics2;
const CustomReportMain = (props) => {
  const ResponsiveReactGridLayout = WidthProvider(RGL);
  const pdfExportComponent = React.useRef(null);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  // const [loadingViewer, setLoadingViewer] = useState(true);
  const [show, setShow] = useState(false);
  const [WSaveFlag, setWSaveFlag] = useState(false);
  const [usememocheck, setuseMemocheck] = useState(false);
  const [acctProfileRptData, setAcctProfileRptData] = useState([]);
  const [acctPortVsModelData, setAcctPortVsModelData] = useState([]);
  const [topHoldingData, setTopHoldingData] = useState([]);
  const [portfoliHoldingsData, setPortfoliHoldingsData] = useState([]);
  const [cashflowData, setcashflowData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setreportData] = useState([]);
  const [viewerReport, setviewerReport] = useState([]);
  //var arr=[];
  var finalStrjson;
  localStorage.setItem(
    "modelId",
    localStorage.getItem("modelId") === null
      ? -1
      : localStorage.getItem("modelId")
  );
  //custom report data fetch
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetCustomReportData();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  // useEffect(()=>{
  //   setLoadingViewer(false);
  // },[])
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));
        GetCustomReportData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const refreshTokenWidget = async () => {
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));
        GetWidgetSettings();
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
  };
  const GetCustomReportData = async (roleId, cntctId, acctId) => {
    // setLoading(true);
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    console.log("Token:", token);
    let UserId = JSON.parse(localStorage.getItem("userId"));
    //let UserId = 269406;
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    //let AcctId = 46140;
    let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    //SetselAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
    let modelId = JSON.parse(localStorage.getItem("modelId")); //-1;
    let invMix = 0;
    let NumOfRows = 10;
    let PageId = 1;
    let TemplateId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    
    const postData = { UserId, AcctId, TemplateId }; //{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
    console.log("template details", postData);
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/Template", postData, config)
      .then((response) => {
        console.log("Template", response);
        
        //console.log(response);
        const rowData = response.data;

        //setreportData(response.data);
        // setreportData(rowDataActiveReport);
        const acctProfData =
          rowData[0].accountProf === null
            ? []
            : rowData[0].accountProf.ocAcctProfile;
        const acctProfColorData =
          rowData[0].accountProf === null
            ? []
            : rowData[0].accountProf.ocAcctProfColorModel;
        const transactionData =
          rowData[0].objLstAcctTransaction === null
            ? []
            : rowData[0].objLstAcctTransaction.ocAcctTransaction;
        for (var i = 0; i < transactionData.length; i++) {
          // let cdt = new Date(transactionData[i].prcsDt);
          // let tradeDt = new Date(transactionData[i].tradeDate);
          // transactionData[i].prcsDt = new Date(cdt.getFullYear(),cdt.getDate(),cdt.getMonth());
          // transactionData[i].tradeDate = new Date(tradeDt.getFullYear(),tradeDt.getDate(),tradeDt.getMonth());
          transactionData[i].tradeDate = parseDate(
            formatDate(transactionData[i].tradeDate, "MM/dd/yyyy")
          );
          transactionData[i].prcsDt = parseDate(
            formatDate(transactionData[i].prcsDt, "MM/dd/yyyy")
          );
          transactionData[i].effectiveDate = parseDate(
            formatDate(transactionData[i].effectiveDate, "MM/dd/yyyy")
          );
          transactionData[i].entryDate = parseDate(
            formatDate(transactionData[i].entryDate, "MM/dd/yyyy")
          );
        }
        transactionDatavar = transactionData;
        const projectedCashFlowData =
          rowData[0].objProjectedCashFlow === null
            ? []
            : rowData[0].objProjectedCashFlow.t1;
        //const assetAllocVsModelData=rowData[0].objAssetsMarketCapital===null?[]:rowData[0].objAssetsMarketCapital.t1;
        const portPerfBySecurityData =
          rowData[0].objRTPortPerfBySecurity === null
            ? []
            : rowData[0].objRTPortPerfBySecurity.t1;
        portfolioBrkdwn1stDatavar =
          rowData[0].objRTPortfolioBreakDown === null
            ? []
            : rowData[0].objRTPortfolioBreakDown.t4;
        portfolioBrkdwn2ndDatavar =
          rowData[0].objRTPortfolioBreakDown === null
            ? []
            : rowData[0].objRTPortfolioBreakDown.t5;
        portfolioBrkdwn3rdDatavar =
          rowData[0].objRTPortfolioBreakDown === null
            ? []
            : rowData[0].objRTPortfolioBreakDown.t6;
        sectorCmprsnDatavar =
          rowData[0].objAcctSectOutput === null
            ? []
            : rowData[0].objAcctSectOutput.ocAcctSectT2;
        snapshotCmprsnDatavar =
          rowData[0].objRTPortfolioSnapShotComparison === null
            ? []
            : rowData[0].objRTPortfolioSnapShotComparison.t1;
        snapshotCmprsncolorDatavar =
          rowData[0].objRTPortfolioSnapShotComparison === null
            ? []
            : rowData[0].objRTPortfolioSnapShotComparison.t4;
        portPerfBySecurityDatavar =
          rowData[0].objRTPortPerfBySecurity === null
            ? []
            : rowData[0].objRTPortPerfBySecurity.t1;
        sctrReturnPerfrmnceDatavar =
          rowData[0].objOcSectorReturnPerformanceOutput === null
            ? []
            : rowData[0].objOcSectorReturnPerformanceOutput.sRP1;
        sctrReturnPerfrmnceChartDatavar =
          rowData[0].objOcSectorReturnPerformanceOutput === null
            ? []
            : rowData[0].objOcSectorReturnPerformanceOutput.sRP3;
        setcashflowData(
          rowData[0].objProjectedCashFlow === null
            ? []
            : rowData[0].objProjectedCashFlow.t1
        );
        //check
        setdailyperfData(rowData[0].objSelectedPeriodPerformanceOutput);
        dailyperfDatavar = rowData[0].objSelectedPeriodPerformanceOutput;
        cashflowDatavar =
          rowData[0].objProjectedCashFlow === null
            ? []
            : rowData[0].objProjectedCashFlow.t1;
        astByMrktCapitalDatavar =
          rowData[0].objAssetsMarketCapital === null
            ? []
            : rowData[0].objAssetsMarketCapital.t1;
        const astmarketCapitalData =
          rowData[0].objAssetsMarketCapital === null
            ? []
            : rowData[0].objAssetsMarketCapital.t1;
        accountHoldingDatavar =
          rowData[0].objAcctHolding === null
            ? []
            : rowData[0].objAcctHolding.ocAcctHolding;
        for (let i = 0; i < accountHoldingDatavar.length; i++) {
          let holdingDt = new Date(accountHoldingDatavar[i].holdingDate);
          let holdingDtNew = parseDate(
            formatDate(accountHoldingDatavar[i].holdingDate, "MM/dd/yyyy")
          );
          accountHoldingDatavar[i].holdingDate = holdingDtNew; // new Date(holdingDt.getFullYear(),holdingDt.getDate(),holdingDt.getMonth());
        }
        const acctProfFinalData = Enumerable.from(acctProfData)
          .join(
            acctProfColorData,
            (pk) => pk.groupId,
            (fk) => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
          )
          .toArray();
        const assetVsModelData =
          rowData[0].assetAllocVsMod === null
            ? []
            : rowData[0].assetAllocVsMod.ocAssetAllocVsModel;
        const assetVsColorModel =
          rowData[0].assetAllocVsMod === null
            ? []
            : rowData[0].assetAllocVsMod.ocAssetColorModel;
        const assetVsModelFinalData = Enumerable.from(assetVsModelData)
          .join(
            assetVsColorModel,
            (pk) => pk.groupId,
            (fk) => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
          )
          .toArray();
        //find
        rowData[0].finalWidgetPositionSet.pos.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );
        for (var i = 0; i < rowData[0].finalWidgetPositionSet.pos.length; i++) {
          rowData[0].finalWidgetPositionSet.pos[i].order = i;
        }
        setPositions(rowData[0].finalWidgetPositionSet.pos);
        //setPositions(JSON.parse(localStorage.getItem("dashboard-positions")));
        setWidgets(
          reviverFunc(
            rowData[0].finalWidgetPositionSet.widgetlist,
            rowData[0].finalWidgetPositionSet.pos
          )
        );
        setuseMemocheck(true);
        const topHoldingsData =
          rowData[0].topHold === null ? [] : rowData[0].topHold.ocTopHolding;
        const portfoliholdingsData =
          rowData[0].portFolioHoldings === null
            ? []
            : rowData[0].portFolioHoldings.ocPortFolioHoldingsMainOutPut;
        portfoliholdingsDatavar = portfoliholdingsData;
        for (let i = 0; i < portfoliholdingsDatavar.length; i++) {
          let priceDt = parseDate(
            formatDate(portfoliholdingsDatavar[i].priceDate, "MM/dd/yyyy")
          ); // new Date(accountHoldingDatavar[i].priceDate);
          portfoliholdingsDatavar[i].priceDate = priceDt;
          // let priceDt = new Date(portfoliholdingsDatavar[i].priceDate);
          // portfoliholdingsDatavar[i].priceDate =new Date(priceDt.getFullYear(),priceDt.getDate(),priceDt.getMonth());
        }
        setAcctProfileRptData(acctProfFinalData);
        acctProfFinalDatavar = acctProfFinalData;
        //localStorage.setItem("acctProfFinalData",JSON.stringify(acctProfFinalData));
        allocmodelDatavar = assetVsModelFinalData;
        //localStorage.setItem("allocmodelData",JSON.stringify(assetVsModelFinalData));
        setAcctPortVsModelData(assetVsModelFinalData);
        fixdincmfndmntlsDatavar =
          rowData[0].objOcFixedIncomeFundamentalsModelOutput === null
            ? []
            : Enumerable.from(
                rowData[0].objOcFixedIncomeFundamentalsModelOutput.fIF1
              )
                .where((w) => w.mtrtyYr !== 0)
                .toArray();
        perfRORDatavar =
          rowData[0].objAccountPerformanceSummary === null
            ? []
            : rowData[0].objAccountPerformanceSummary
                .lstAccountPerformanceSummaryROR;
        perfMjrAsstSummaryDatavar =
          rowData[0].objAccountPerformanceSummary === null
            ? []
            : rowData[0].objAccountPerformanceSummary
                .lstAccountPerformanceSummaryActivity;
        perfChartDatavar =
          rowData[0].objAccountPerformanceSummary === null
            ? []
            : rowData[0].objAccountPerformanceSummary
                .lstAccountPerformanceSummaryAllocation;
        setTopHoldingData(topHoldingsData);
        setPortfoliHoldingsData(portfoliholdingsData);
        assetVsModelFinalDatavar = assetVsModelFinalData;
        //localStorage.setItem("assetVsModelFinalData", JSON.stringify(assetVsModelFinalData));
        topHoldingDatavar = topHoldingsData;
        purchaseAndSaleData =
          rowData[0].objPurchaseAndSaleModel === null
            ? []
            : rowData[0].objPurchaseAndSaleModel.t1;
        for (let i = 0; i < purchaseAndSaleData.length; i++) {
          purchaseAndSaleData[i].trdDt = parseDate(
            formatDate(purchaseAndSaleData[i].trdDt, "MM/dd/yyyy")
          );
        }
        //find
        astVsModelData =
          rowData[0].objRTAssetAllocationVsModel === null
            ? []
            : rowData[0].objRTAssetAllocationVsModel.t1;
        for (let i = 0; i < astVsModelData.length; i++) {
          astVsModelData[i].mdlWegh = parseNumber(
            formatNumber(astVsModelData[i].mdlWegh, "n2")
          );
        }
        TimeWtdRtnRptChartDatavar =
          rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput === null
            ? []
            : rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput
                .lstRTTimeWtdReturnT1;
        TimeWtdRtnRptGridDatavar =
          rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput === null
            ? []
            : rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput
                .lstRTTimeWtdReturnT1;
        //maturity
        fimMaturityDeatils =
          rowData[0].objFixedIncomeMaturityModelOutput === null
            ? []
            : rowData[0].objFixedIncomeMaturityModelOutput.fIM1;
        fimMaturityLadrCallDetails =
          rowData[0].objFixedIncomeMaturityModelOutput === null
            ? []
            : rowData[0].objFixedIncomeMaturityModelOutput.fIM2;
        fimMatrtyDtVsCallPutDt =
          rowData[0].objFixedIncomeMaturityModelOutput === null
            ? []
            : rowData[0].objFixedIncomeMaturityModelOutput.fIM3;
        fipoBondQultySctr =
          rowData[0].objFixedIncomePortfolioOverviewOutput === null
            ? []
            : rowData[0].objFixedIncomePortfolioOverviewOutput
                .lstFixedIncomePortfolioOverviewT2;
        fipoMaurityLadder =
          rowData[0].objFixedIncomePortfolioOverviewOutput === null
            ? []
            : rowData[0].objFixedIncomePortfolioOverviewOutput
                .lstFixedIncomePortfolioOverviewT3;
        fipoBondByMinorCls =
          rowData[0].objFixedIncomePortfolioOverviewOutput === null
            ? []
            : rowData[0].objFixedIncomePortfolioOverviewOutput
                .lstFixedIncomePortfolioOverviewT4;
        fipoBondByMjrIndstry =
          rowData[0].objFixedIncomePortfolioOverviewOutput === null
            ? []
            : rowData[0].objFixedIncomePortfolioOverviewOutput
                .lstFixedIncomePortfolioOverviewT5;
        ////For New Four Reports
        // Asset growth and allocation

        AssetGrowthAlloc1 =
          rowData[0].objRTGetAstGrwthAlctnModel === null
            ? []
            : rowData[0].objRTGetAstGrwthAlctnModel.t1;

        for (let i = 0; i < AssetGrowthAlloc1.length; i++) {
          AssetGrowthAlloc1[i].retVal = parseNumber(
            formatNumber(AssetGrowthAlloc1[i].retVal, "n2")
          );
        }
        AssetGrowthAlloc2 =
          rowData[0].objRTGetAstGrwthAlctnModel === null
            ? []
            : rowData[0].objRTGetAstGrwthAlctnModel.t2;
        // historical monthly performence
        HistoricalMonthlyPerformance1 =
          rowData[0].objGetHistoricalMonthlyPerfModel === null
            ? []
            : rowData[0].objGetHistoricalMonthlyPerfModel.t1;
        HistoricalMonthlyPerformance2 =
          rowData[0].objGetHistoricalMonthlyPerfModel === null
            ? []
            : rowData[0].objGetHistoricalMonthlyPerfModel.t2;
        HistoricalMonthlyPerformance3 =
          rowData[0].objGetHistoricalMonthlyPerfModel === null
            ? []
            : rowData[0].objGetHistoricalMonthlyPerfModel.t3;
        HistoricalMonthlyPerformance4 =
          rowData[0].objGetHistoricalMonthlyPerfModel === null
            ? []
            : rowData[0].objGetHistoricalMonthlyPerfModel.t4;
        HistoricalMonthlyPerformance5 =
          rowData[0].objGetHistoricalMonthlyPerfModel === null
            ? []
            : rowData[0].objGetHistoricalMonthlyPerfModel.t5;
        //Annual Portfolio New t2 = t1 + t2
        let newCombinedDatat2ANDt3 = null;
        if (rowData[0].objRTAnnualPortfolio != null) {
          //////////////////// for annual portfolio values////////
          // AnnualPortfolio1 = rowData[0].objRTAnnualPortfolio === null ? [] : rowData[0].objRTAnnualPortfolio.t1;
          // AnnualPortfolio2 = rowData[0].objRTAnnualPortfolio === null ? [] : rowData[0].objRTAnnualPortfolio.t2;
          // AnnualPortfolio3 = rowData[0].objRTAnnualPortfolio === null ? [] : rowData[0].objRTAnnualPortfolio.t3;
          const t2DataLength = rowData[0].objRTAnnualPortfolio.t2.length;
          // console.log(t2DataLength);
          // console.log("Merged Data", mergedData);
          // Convert the combined array to a JSON string
          // const combinedJson = JSON.stringify(mergedData);
          // console.log("String JSON",combinedJson);
          const t1Data = rowData[0].objRTAnnualPortfolio.t1 || [];
          const t2Data = rowData[0].objRTAnnualPortfolio.t2 || [];
          const t3Data = rowData[0].objRTAnnualPortfolio.t3 || [];
          // console.log("t1 Data", t1Data)
          //console.log("t2 Data", t2Data);
          //console.log("t3 Data", t3Data);
          const perfTmpltCtgryNms = t3Data.map((item) => item.perfTmpltCtgryNm);
          // console.log("perfTmp", perfTmpltCtgryNms);
          const newT2Data = t2Data.map((item) => {
            let colSeq = null;
            if (item.columnName === "StartMarket") {
              colSeq = 1;
            } else if (item.columnName === "EndMarket") {
              colSeq = 2;
            } else if (item.columnName === "StartAcrlIncm") {
              colSeq = 3;
            } else if (item.columnName === "EndAcrlIncm") {
              colSeq = 4;
            } else if (item.columnName === "StartPortVal") {
              colSeq = 5;
            } else if (item.columnName === "EndPortVal") {
              colSeq = 6;
            } else if (item.columnName === "IncomeEarned") {
              colSeq = 7;
            } else if (item.columnName === "IncomeReturn") {
              colSeq = 8;
            } else if (item.columnName === "Contribution") {
              colSeq = 9;
            } else if (item.columnName === "Withdrwals") {
              colSeq = 10;
            } else if (item.columnName === "MarketApprctn") {
              colSeq = 11;
            } else if (item.columnName === "TotalReturnG") {
              colSeq = 12;
            } else if (item.columnName === "CapitalReturn") {
              colSeq = 13;
            }
            return {
              ...item,
              colSeq,
            };
          });
          // console.log("New T2Data", newT2Data);
          const headers = t1Data.length > 0 ? t1Data[0] : {};
          const headerTitles = {
            year1: headers.year1 || "Year 1",
            year2: headers.year2 || "Year 2",
            year3: headers.year3 || "Year 3",
            year4: headers.year4 || "Year 4",
            year5: headers.year5 || "Year 5",
            year6: headers.year6 || "Year 6",
          };
          //  console.log("HeaderTitles", headerTitles);
          const columnNameMapping = {
            StartMarket: "Beginning Market Value",
            StartAcrlIncm: "Beginning Accrued Income",
            StartPortVal: "Beginning Portfolio Value",
            Contribution: "Contributions",
            Withdrwals: "Withdrawals",
            IncomeEarned: "Income Earned",
            EndMarket: "Ending Market Value",
            EndAcrlIncm: "Ending Accrued Income",
            EndPortVal: "Ending Portfolio Value",
            MarketApprctn: "Market Appreciation",
            TotalReturnG: "Total Return (Gross Fees)",
            CapitalReturn: "Capital Only",
            IncomeReturn: "Income Only",
          };
          const transformedData = newT2Data.map((item) =>
            // console.log("Item", item),
            ({
              ...item,
              columnName: columnNameMapping[item.columnName] || item.columnName,
            })
          );
          // console.log("TransformedData", transformedData);
          let transformedDataLength = transformedData.length;
          //console.log("transformedData Length", transformedDataLength);
          const combinedData = [
            ...transformedData.map((item) => ({
              columnName: item.columnName,
              extrnlAcctId: item.extrnlAcctId,
              shrtNm: item.shrtNm,
              year1: item.year1,
              year2: item.year2,
              year3: item.year3,
              year4: item.year4,
              year5: item.year5,
              year6: item.year6,
            })),
            ...t3Data.map((item) => ({
              columnName: item.perfTmpltCtgryNm,
              year1: item.year1,
              year2: item.year2,
              year3: item.year3,
              year4: item.year4,
              year5: item.year5,
              year6: item.year6,
            })),
          ];
          console.log("Combined Data", combinedData);
          // let nextColSeq = 14;
          const newCombinedData = combinedData.map((item) => {
            let colSeq = null;
            if (item.columnName === "Beginning Market Value") {
              colSeq = 1;
            } else if (item.columnName === "Ending Market Value") {
              colSeq = 2;
            } else if (item.columnName === "Beginning Accrued Income") {
              colSeq = 3;
            } else if (item.columnName === "Ending Accrued Income") {
              colSeq = 4;
            } else if (item.columnName === "Beginning Portfolio Value") {
              colSeq = 5;
            } else if (item.columnName === "Ending Portfolio Value") {
              colSeq = 6;
            } else if (item.columnName === "Income Earned") {
              colSeq = 7;
            } else if (item.columnName === "Income Only") {
              colSeq = 8;
            } else if (item.columnName === "Contributions") {
              colSeq = 9;
            } else if (item.columnName === "Withdrawals") {
              colSeq = 10;
            } else if (item.columnName === "Market Appreciation") {
              colSeq = 11;
            } else if (item.columnName === "Total Return (Gross Fees)") {
              colSeq = 12;
            } else if (item.columnName === "Capital Only") {
              colSeq = 13;
            }
            if (colSeq === null) {
              transformedDataLength++;
              colSeq = transformedDataLength;
            }
            return {
              ...item,
              colSeq,
            };
          });
          console.log("New Combined Data", newCombinedData);
          console.log("Finally Transformed Data Length", transformedDataLength);
          newCombinedDatat2ANDt3 = {
            t1: t1Data,
            t2: newCombinedData,
          };
          console.log("Type of ", typeof newCombinedDatat2ANDt3);
          console.log("Type of ", newCombinedDatat2ANDt3);
          ////////////////////////////////////////////////////
        }
        AnnualPortfolio1 =
          rowData[0].objRTAnnualPortfolio === null
            ? []
            : rowData[0].objRTAnnualPortfolio.t1;
        AnnualPortfolio2 =
          newCombinedDatat2ANDt3 === null ? [] : newCombinedDatat2ANDt3.t2; //rowData[0].objRTAnnualPortfolio === null ? [] : rowData[0].objRTAnnualPortfolio.t2;
        AnnualPortfolio3 =
          rowData[0].objRTAnnualPortfolio === null
            ? []
            : rowData[0].objRTAnnualPortfolio.t3;
        //BenchmarkselectedPrioudPerformance = rowData[0].BenchmarkselectedPrioudPerformance === null ? [] : rowData[0].BenchmarkselectedPrioudPerformance;
        //Performence risk matrics
        PerformanceRiskMetrics1 =
          rowData[0].objGetPerformanceRiskMetrics === null
            ? []
            : rowData[0].objGetPerformanceRiskMetrics.t1;
        PerformanceRiskMetrics2 =
          rowData[0].objGetPerformanceRiskMetrics === null
            ? []
            : rowData[0].objGetPerformanceRiskMetrics.t2;
        ///// For New For Reports
        //localStorage.setItem("topHoldingData", JSON.stringify(topHoldingsData));
        //localStorage.setItem("portfoliHoldingData", JSON.stringify(portfoliholdingsData));
        localStorage.setItem(
          "txtTableOfContent",
          rowData[0].finalWidgetPositionSet.templateTextBoxList[1].textBoxVal
        );
        localStorage.setItem(
          "txtIntro",
          rowData[0].finalWidgetPositionSet.templateTextBoxList[2].textBoxVal
        );
        localStorage.setItem(
          "txtConclusion",
          rowData[0].finalWidgetPositionSet.templateTextBoxList[3].textBoxVal
        );
        localStorage.setItem(
          "txtCoverPage",
          rowData[0].finalWidgetPositionSet.templateTextBoxList[0].textBoxVal
        );
        acctPrflSmryDatavar =
          rowData[0].accountProf === null
            ? []
            : rowData[0].accountProf.ocAcctProfile;
        setFlag(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };
  const showpreview = async () => {
    reviverFunc(widgets, positions, 1);
    setShow(true);
    for (var i = 0; i < widgets.length; i++) {
      widgets[i].reorderable = false;
      widgets[i].resizable = false;
    }
  };
  const viewerRef = React.useRef();
  const designerRef = React.useRef();
  // const [reportStorage, setReportStorage] = React.useState(new Map());
  // const obj = new object();
  const [reportStorage, setReportStorage] = React.useState();
  // const [designerVisible, setDesignerVisible] = React.useState(true);
  const [designerVisible, setDesignerVisible] = React.useState(false);
  async function loadReport() {
    // load report definition from the file
    const reportResponse = await fetch("FinalDemoMergeLatestCopy.rdlx-json");
    const report = await reportResponse.json();
    return report;
  }
  function setReportJsondata() {
    let coverPageRpt =
      localStorage.getItem("txtCoverPage") === null
        ? ""
        : localStorage.getItem("txtCoverPage");
    let newElementCov = document.createElement("div");
    newElementCov.innerHTML = coverPageRpt;
    let allImagesCov = newElementCov.querySelectorAll("img");
    if (allImagesCov.length > 0) {
      let maxWidthInches = 10;
      let maxHeightInches = 5.5;
      newElementCov.querySelectorAll("img").forEach((itemcov) => {
        let orgWidthCov =
          itemcov.naturalWidth / 96 > maxWidthInches
            ? maxWidthInches + "in"
            : itemcov.naturalWidth / 96 + "in";
        let orgHeightCov =
          itemcov.naturalHeight / 96 > maxHeightInches
            ? maxHeightInches + "in"
            : itemcov.naturalHeight / 96 + "in";
        itemcov.style.width = orgWidthCov;
        itemcov.style.height = orgHeightCov;
        // console.log("orgwidthcov = ",orgWidthCov,orgHeightCov);
      });
      coverPageRpt = newElementCov.innerHTML;
    }
    let accountSelected =
      localStorage.getItem("AcctSelected") === null
        ? ""
        : localStorage.getItem("AcctSelected");
    // let tableOfContent = (localStorage.getItem('txtTableOfContent')) === null ? "" : (localStorage.getItem('txtTableOfContent'));
    let introductionRpt =
      localStorage.getItem("txtIntro") === null
        ? ""
        : localStorage.getItem("txtIntro");
    let newElement = document.createElement("div");
    newElement.innerHTML = introductionRpt;
    let allImages = newElement.querySelectorAll("img");
    if (allImages.length > 0) {
      let maxWidthInches = 8.5;
      let maxHeightInches = 5.5;
      newElement.querySelectorAll("img").forEach((item) => {
        let orgWidth =
          item.naturalWidth / 96 > maxWidthInches
            ? maxWidthInches + "in"
            : item.naturalWidth / 96 + "in";
        let orgHeight =
          item.naturalHeight / 96 > maxHeightInches
            ? maxHeightInches + "in"
            : item.naturalHeight / 96 + "in";
        item.style.width = orgWidth;
        item.style.height = orgHeight;
      });
      introductionRpt = newElement.innerHTML;
    }
    let conclusionRpt =
      localStorage.getItem("txtConclusion") === null
        ? ""
        : localStorage.getItem("txtConclusion");
    let newElementCon = document.createElement("div");
    newElementCon.innerHTML = conclusionRpt;
    let allImagesCon = newElementCon.querySelectorAll("img");
    if (allImagesCon.length > 0) {
      let maxWidthInches = 8.5;
      let maxHeightInches = 5.5;
      newElementCon.querySelectorAll("img").forEach((itemcon) => {
        let orgWidthCon =
          itemcon.naturalWidth / 96 > maxWidthInches
            ? maxWidthInches + "in"
            : itemcon.naturalWidth / 96 + "in";
        let orgHeightCon =
          itemcon.naturalHeight / 96 > maxHeightInches
            ? maxHeightInches + "in"
            : itemcon.naturalHeight / 96 + "in";
        itemcon.style.width = orgWidthCon;
        itemcon.style.height = orgHeightCon;
      });
      conclusionRpt = newElementCon.innerHTML;
    }
    let obj = new Object();
    obj.ocAccountTransaction = transactionDatavar;
    obj.cashflowData = cashflowDatavar;
    obj.purchaseAndSaleData = purchaseAndSaleData;
    ////////////////////////Added for Fixed income fundamentals report YTW calculation /////////
    for (var i = 0; i < fixdincmfndmntlsDatavar.length; i++) {
      if (fixdincmfndmntlsDatavar[i].yldCalPut != 0) {
        if (
          fixdincmfndmntlsDatavar[i].yldCalPut >
          fixdincmfndmntlsDatavar[i].yldToMtrty
        )
          fixdincmfndmntlsDatavar[i].yldCalPut =
            fixdincmfndmntlsDatavar[i].yldToMtrty;
        else if (
          fixdincmfndmntlsDatavar[i].yldCalPut ==
          fixdincmfndmntlsDatavar[i].yldToMtrty
        )
          fixdincmfndmntlsDatavar.yldCalPut =
            fixdincmfndmntlsDatavar.yldToMtrty;
        else if (
          fixdincmfndmntlsDatavar.yldCalPut < fixdincmfndmntlsDatavar.yldToMtrty
        )
          fixdincmfndmntlsDatavar.yldCalPut = fixdincmfndmntlsDatavar.yldCalPut;
      }
      if (fixdincmfndmntlsDatavar[i].yldCalPut == 0) {
        fixdincmfndmntlsDatavar[i].yldCalPut =
          fixdincmfndmntlsDatavar[i].yldToMtrty;
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////
    obj.fixedincomefundamentalsfIf1 = fixdincmfndmntlsDatavar;
    obj.sRP1 = sctrReturnPerfrmnceDatavar;
    obj.lstAccountPerformanceSummaryROR = perfRORDatavar;
    obj.lstAccountPerformanceSummaryActivity = perfMjrAsstSummaryDatavar;
    for (let i = 0; i < perfChartDatavar.length; i++) {
      perfChartDatavar[i].startPercent = parseNumber(
        formatNumber(perfChartDatavar[i].startPercent, "n2")
      );
      perfChartDatavar[i].endPercent = parseNumber(
        formatNumber(perfChartDatavar[i].endPercent, "n2")
      );
    }
    obj.lstAccountPerformanceSummaryAllocation = perfChartDatavar;
    obj.amct1 = astByMrktCapitalDatavar;
    obj.portPrefBySecT1 = portPerfBySecurityDatavar;
    for (var i = 0; i < sctrReturnPerfrmnceChartDatavar.length; i++) {
      sctrReturnPerfrmnceChartDatavar[i].growth = Number(
        formatNumber(
          sctrReturnPerfrmnceChartDatavar[i].growth,
          "#,##.00"
        ).replace(/,/g, "")
      );
      sctrReturnPerfrmnceChartDatavar[i].investment = Number(
        formatNumber(
          sctrReturnPerfrmnceChartDatavar[i].investment,
          "#,##.00"
        ).replace(/,/g, "")
      );
    }
    obj.sRP3 = sctrReturnPerfrmnceChartDatavar;
    obj.introduction = introductionRpt;
    obj.coverPage = coverPageRpt;
    obj.conclusion = conclusionRpt;
    for (let i = 0; i < acctProfFinalDatavar.length; i++) {
      acctProfFinalDatavar[i].marketPercent = parseNumber(
        formatNumber(acctProfFinalDatavar[i].marketPercent, "n2")
      );
      acctProfFinalDatavar[i].incptnDt = Moment(
        acctProfFinalDatavar[i].incptnDt
      ).format("MM/DD/YYYY");
    }
    obj.Account = acctProfFinalDatavar;
    for (let i = 0; i < sectorCmprsnDatavar.length; i++) {
      sectorCmprsnDatavar[i].marketPercent = parseNumber(
        formatNumber(sectorCmprsnDatavar[i].marketPercent, "n2")
      );
      sectorCmprsnDatavar[i].bmPcnt = parseNumber(
        formatNumber(sectorCmprsnDatavar[i].bmPcnt, "n2")
      );
    }
    obj.ocAcctSectT2 = sectorCmprsnDatavar;
    for (let i = 0; i < portfoliholdingsDatavar.length; i++) {
      portfoliholdingsDatavar[i].units = parseNumber(
        formatNumber(portfoliholdingsDatavar[i].units, "n2")
      );
    }
    obj.ocPortFolioHoldingsMainOutPut = portfoliholdingsDatavar;
    for (let i = 0; i < accountHoldingDatavar.length; i++) {
      accountHoldingDatavar[i].principalCash = parseNumber(
        formatNumber(accountHoldingDatavar[i].principalCash, "n2")
      );
      accountHoldingDatavar[i].incomeCash = parseNumber(
        formatNumber(accountHoldingDatavar[i].incomeCash, "n2")
      );
      accountHoldingDatavar[i].investedIncome = parseNumber(
        formatNumber(accountHoldingDatavar[i].investedIncome, "n2")
      );
      //  let priceDt = parseDate(formatDate(accountHoldingDatavar[i].priceDate,'MM/dd/yyyy'));  // new Date(accountHoldingDatavar[i].priceDate);
      //  accountHoldingDatavar[i].priceDate =priceDt;// new Date(priceDt.getFullYear(),priceDt.getDate(),priceDt.getMonth());
    }
    obj.ocAcctHolding = accountHoldingDatavar;
    obj.portBreakT4 = portfolioBrkdwn1stDatavar;
    obj.portBreakT5 = portfolioBrkdwn2ndDatavar;
    obj.portBreakT6 = portfolioBrkdwn3rdDatavar;
    obj.portSnapComparisionT1 = snapshotCmprsnDatavar;
    obj.assetVsAllocationModelT1 = astVsModelData;
    obj.lstFixedIncomePortfolioOverviewT2 = fipoBondQultySctr;
    obj.lstFixedIncomePortfolioOverviewT3 = fipoMaurityLadder;
    for (let i = 0; i < fipoBondByMinorCls.length; i++) {
      fipoBondByMinorCls[i].classPercent = parseNumber(
        formatNumber(fipoBondByMinorCls[i].classPercent, "n2")
      );
    }
    obj.lstFixedIncomePortfolioOverviewT4 = fipoBondByMinorCls;
    for (let i = 0; i < fipoBondByMjrIndstry.length; i++) {
      fipoBondByMjrIndstry[i].sectorPct = parseNumber(
        formatNumber(fipoBondByMjrIndstry[i].sectorPct, "n2")
      );
    }
    obj.lstFixedIncomePortfolioOverviewT5 = fipoBondByMjrIndstry;
    obj.fIM1 = fimMaturityDeatils;
    obj.fiM2 = fimMaturityLadrCallDetails;
    obj.fiM3 = fimMatrtyDtVsCallPutDt;
    for (let i = 0; i < TimeWtdRtnRptGridDatavar.length; i++) {
      TimeWtdRtnRptGridDatavar[i].threeYear = parseNumber(
        formatNumber(TimeWtdRtnRptGridDatavar[i].threeYear, "n2")
      );
      TimeWtdRtnRptGridDatavar[i].fiveYear = parseNumber(
        formatNumber(TimeWtdRtnRptGridDatavar[i].fiveYear, "n2")
      );
      TimeWtdRtnRptGridDatavar[i].tenYear = parseNumber(
        formatNumber(TimeWtdRtnRptGridDatavar[i].tenYear, "n2")
      );
      TimeWtdRtnRptGridDatavar[i].inception = parseNumber(
        formatNumber(TimeWtdRtnRptGridDatavar[i].inception, "n2")
      );
    }
    obj.lstRTTimeWtdReturnT1 = TimeWtdRtnRptGridDatavar;
    accountSelected = JSON.parse(accountSelected);
    obj.selectedAccount =
      accountSelected.extrnlAcctId != undefined
        ? "Account: " + accountSelected.extrnlAcctId
        : null;
    //
    //let  pConsName = localStorage.getItem('pConsName');
    let acctId = localStorage.getItem("SelAcctId"); // localStorage.getItem('SelAcctNm');
    if (localStorage.getItem("pConsId") != null) {
      if (
        JSON.parse(localStorage.getItem("pConsId")) > 0 &&
        JSON.parse(acctId) === 0
      ) {
        obj.selectedAccount =
          "Relationship: " + localStorage.getItem("pConsName");
      } else {
        obj.selectedAccount = "Account: " + localStorage.getItem("SelAcctNm");
      }
      //
    }
    obj.lstSelectedPeriodT1 =
      dailyperfDatavar === null ? [] : dailyperfDatavar.lstSelectedPeriodT1;
    
    // New 4 reports
    obj.AssetGrowthAlloc1 = AssetGrowthAlloc1 === null ? [] : AssetGrowthAlloc1;
    obj.AssetGrowthAlloc2 = AssetGrowthAlloc2 === null ? [] : AssetGrowthAlloc2;
    obj.HistoricalMonthlyPerformance1 =
      HistoricalMonthlyPerformance1 === null
        ? []
        : HistoricalMonthlyPerformance1;
    obj.HistoricalMonthlyPerformance2 =
      HistoricalMonthlyPerformance2 === null
        ? []
        : HistoricalMonthlyPerformance2;
    obj.HistoricalMonthlyPerformance3 =
      HistoricalMonthlyPerformance3 === null
        ? []
        : HistoricalMonthlyPerformance3;
    obj.HistoricalMonthlyPerformance4 =
      HistoricalMonthlyPerformance4 === null
        ? []
        : HistoricalMonthlyPerformance4;
    obj.HistoricalMonthlyPerformance5 =
      HistoricalMonthlyPerformance5 === null
        ? []
        : HistoricalMonthlyPerformance5;
    obj.AnnualPortfolio1 = AnnualPortfolio1 === null ? [] : AnnualPortfolio1;
    obj.AnnualPortfolio2 = AnnualPortfolio2 === null ? [] : AnnualPortfolio2;
    obj.AnnualPortfolio3 = AnnualPortfolio3 === null ? [] : AnnualPortfolio3;
    //  // obj.BenchmarkselectedPrioudPerformance= BenchmarkselectedPrioudPerformance === null?[]:BenchmarkselectedPrioudPerformance;
    obj.PerformanceRiskMetrics1 =
      PerformanceRiskMetrics1 === null ? [] : PerformanceRiskMetrics1;
    obj.PerformanceRiskMetrics2 =
      PerformanceRiskMetrics2 === null ? [] : PerformanceRiskMetrics2;
    // AssetGrowthAlloc1 = [];
    // AssetGrowthAlloc2 = [];
    // HistoricalMonthlyPerformance1 = [];
    // HistoricalMonthlyPerformance2 = [];
    // HistoricalMonthlyPerformance3 = [];
    // HistoricalMonthlyPerformance4 = [];
    // HistoricalMonthlyPerformance5 = [];
    // AnnualPortfolio1 = [];
    // AnnualPortfolio2 = [];
    // PerformanceRiskMetrics1 = [];
    //PerformanceRiskMetrics2 = [];
    // new 4 reports
    let rptJsonData = JSON.stringify(obj);
    return rptJsonData;
  }
  async function openReport() {
    const report = await loadReport();
    // setLoadingViewer(true);
    console.log(report);
    // let rptdata=setReportJsondata();
    // report.DataSources[0].ConnectionProperties.ConnectString =
    //   "jsondata=" + rptdata;
    const contSectionList = {
      1: "CoverPage",
      2: "TableOfContents",
      3: "Introduction",
      4: "AccountProfileCoverPage|AccountProfile",
      5: "AccountProfileCoverPage|AccountProfile",
      6: "AccountProfileCoverPage|AccountProfile",
      7: "AccountProfileCoverPage|AccountProfile",
      8: "AccountProfileCoverPage|AccountProfile",
      9: "AccountProfileCoverPage|AccountProfile",
      10: "PortfolioHoldingsCoverPage|PortfolioHoldings",
      11: "FixedIncomeFundamentalsCoverPage|FixedIncomeFundamentals",
      12: "AccountHoldingCoverPage|AccountHolding",
      13: "CashFlowCoverPage|CashFlow",
      14: "AccountPerformanceSummaryCoverPage|AccountPerformanceSummary",
      15: "AccountPerformanceSummaryCoverPage|AccountPerformanceSummary",
      16: "AccountPerformanceSummaryCoverPage|AccountPerformanceSummary",
      17: "AssetMarketByCapitalCoverPage|AssetMarketByCapital",
      18: "PurchaseSaleCoverPage|PurchaseAndSale",
      19: "AssetAllocationVsModelCoverPage|AssetAllocationVsModel",
      20: "AssetAllocationVsModelCoverPage|AssetAllocationVsModel",
      21: "AssetAllocationVsModelCoverPage|AssetAllocationVsModel",
      22: "AssetAllocationVsModelCoverPage|AssetAllocationVsModel",
      23: "AccountTransactionsCoverPage|Transaction",
      24: "AccountSectorsComparisonCoverPage|AccountSectorComparison",
      25: "AccountSectorsComparisonCoverPage|AccountSectorComparison",
      26: "PortfolioPerformanceBySecurityCoverPage|PortfolioPerformanceBySecurity",
      27: "PortfolioBreakdownCoverPage|PortfolioBreakdown",
      28: "PortfolioBreakdownCoverPage|PortfolioBreakdown",
      29: "PortfolioBreakdownCoverPage|PortfolioBreakdown",
      30: "PortfolioSnapshotComparisionCoverPage|PortfolioSnapshotComparision",
      31: "PortfolioSnapshotComparisionCoverPage|PortfolioSnapshotComparision",
      32: "PortfolioSnapshotComparisionCoverPage|PortfolioSnapshotComparision",
      33: "PortfolioSnapshotComparisionCoverPage|PortfolioSnapshotComparision",
      34: "FixedIncomeMaturityLadderCoverPage|FixedIncomeMaturityLadder",
      35: "FixedIncomeMaturityLadderCoverPage|FixedIncomeMaturityLadder",
      36: "FixedIncomeMaturityLadderCoverPage|FixedIncomeMaturityLadder",
      37: "PerformanceSectorReturnsCoverPage|Performance",
      38: "PerformanceSectorReturnsCoverPage|Performance",
      39: "TimeWeightedReturnForSelectedPeriodCoverPage|TimeWeightedReturnForSelectedPeriod",
      40: "TimeWeightedReturnForSelectedPeriodCoverPage|TimeWeightedReturnForSelectedPeriod",
      41: "FixedIncomePortfolioOverviewCoverPage|FixedIncomePortfolioOverview|FixedIncomePortfolioOverview2",
      42: "FixedIncomePortfolioOverviewCoverPage|FixedIncomePortfolioOverview|FixedIncomePortfolioOverview2",
      43: "FixedIncomePortfolioOverviewCoverPage|FixedIncomePortfolioOverview|FixedIncomePortfolioOverview2",
      44: "FixedIncomePortfolioOverviewCoverPage|FixedIncomePortfolioOverview|FixedIncomePortfolioOverview2",
      45: "HistoricalMarketPerformanceCoverPage|HistoricalMarketPerformance",
      //46: "Conclusion"    previous
      46: "AssetGrowthAndAllocationCoverPage|AssetGrowthAndAllocation|AssetGrowthAndAllocation1",
      47: "AnnualPortfolioValuesCoverPage|AnnualPortfolioValues",
      48: "PerformanceRiskMetricsCoverPage|PerformanceRiskMetrics",
      49: "HistoricalMonthlyPerformanceCoverPage|HistoricalMonthlyPerformance",
      50: "Conclusion",
    };
    let newWidPos = Array.apply(null, Array(50)).map(function () {});
    //let widgetrpt =  JSON.parse(localStorage.getItem("widgetrpt"));
    let widgetrpt = widgets;
    let posRpt = positions;
    for (let i1 = 0; i1 < widgetrpt.length; i1++) {
      if (contSectionList[widgetrpt[i1].id] != undefined) {
        if (newWidPos.indexOf(contSectionList[widgetrpt[i1].id]) == -1) {
          newWidPos[posRpt[i1].order] = contSectionList[widgetrpt[i1].id];
        }
      }
    }
    //report.ReportSections=new Array(report.ReportSections[0],report.ReportSections[2]);
    let reportSectionsAll = report.ReportSections;
    let reportdisplayArray = new Array();
    for (let i3 = 0; i3 < newWidPos.length; i3++) {
      if (newWidPos[i3] != undefined) {
        let newcslen = newWidPos[i3].split("|").length;
        let newWidPosItem = newWidPos[i3].split("|")[0];
        for (let i6 = 0; i6 < reportSectionsAll.length; i6++) {
          if (newWidPosItem == reportSectionsAll[i6].Name) {
            reportdisplayArray.push(reportSectionsAll[i6]);
            break;
          }
        }
        if (newcslen > 1) {
          let newWidPosItem2 = newWidPos[i3].split("|")[1];
          for (let i4 = 0; i4 < reportSectionsAll.length; i4++) {
            if (newWidPosItem2 == reportSectionsAll[i4].Name) {
              reportdisplayArray.push(reportSectionsAll[i4]);
              break;
            }
          }
        }
        if (newcslen > 2) {
          let newWidPosItem3 = newWidPos[i3].split("|")[2];
          for (let i5 = 0; i5 < reportSectionsAll.length; i5++) {
            if (newWidPosItem3 == reportSectionsAll[i5].Name) {
              reportdisplayArray.push(reportSectionsAll[i5]);
              break;
            }
          }
        }
      }
    }
    console.log("ActiveReportSection: ", reportdisplayArray);
    report.ReportSections = reportdisplayArray;
    //start appoach for data widget movement
    // const reportObjWidId = [{
    //   "DataSetName": "FixedIncomeFundamentals",
    //   "WidgetId": 11,
    // },
    // {
    //   "DataSetName": "cashflowData",
    //   "WidgetId": 13,
    // },
    // {
    //   "DataSetName": "purchaseAndSaleData",
    //   "WidgetId": 18,
    // },
    // {
    //   "DataSetName": "tranDataRpt",
    //   "WidgetId": 23,
    // }
    // ];
    // start: code to change pos dynamically
    // let newWidPos = Array.apply(null, Array(50)).map(function () { });
    // let reportAllItems = report.ReportSections[0].Body.ReportItems;
    // console.log("reportAllItems: ", reportAllItems);
    // for (let i1 = 0; i1 < reportAllItems.length; i1++) {
    //   console.log(widgets);
    //   console.log("item1:", reportAllItems[i1]);
    //   let currentWidId = '';
    //   for (let i3 = 0; i3 < reportObjWidId.length; i3++) {
    //     let dname = reportObjWidId[i3].DataSetName;
    //     if (reportAllItems[i1].DataSetName.indexOf(dname) > -1) {
    //       currentWidId = reportObjWidId[i3].WidgetId;
    //       break;
    //     }
    //   }
    //   for (let i2 = 0; i2 < widgets.length; i2++) {
    //     if (widgets[i2].id === currentWidId && widgets[i2].active === true) {
    //       let newWidPosObj = {};
    //       newWidPosObj[reportAllItems[i1].DataSetName] = positions[i2];
    //       newWidPos[positions[i2].order] = newWidPosObj;
    //       break;
    //     }
    //   }
    //   //console.log("newwidpos data: ",newWidPos);
    // }
    // console.log("newwidpos data: ", newWidPos);
    // let previousTop = 0;
    // let previousHeight = 0.5;
    // for (let i4 = 0; i4 < newWidPos.length; i4++) {
    //   if (newWidPos[i4] !== undefined)
    //     for (let i5 = 0; i5 < reportAllItems.length; i5++) {
    //       if (reportAllItems[i5].DataSetName.indexOf(Object.keys(newWidPos[i4])[0]) > -1) {
    //         reportAllItems[i5].Visibility.Hidden=false;
    //         reportAllItems[i5].Top = previousTop + Number(reportAllItems[i5].Height.split("in")[0]) + "in";
    //         //reportAllItems[i5].Height = previousHeight + "in";
    //         previousHeight = Number(reportAllItems[i5].Height.split("in")[0]);
    //         previousTop = previousTop + previousHeight;
    //       }
    //     }
    // }
    // end logic for widget movement
    //  report.ReportSections=new Array(report.ReportSections[0],report.ReportSections[2]);
    //report.ReportSections=new Array(report.ReportSections);
    // end: code to do position manipulation dynamically
    // viewerRef.current.Viewer.open(report);
    // designerRef.current.setReport({definition: report,  displayName: "my report"});
    // we need to open designer here
    return report;
    //left-right analysis for continous pages
    // report.ReportSections[0].Body.ReportItems[0].Top = '0.5in';
    // report.ReportSections[0].Body.ReportItems[0].Left = '0.15in';
    // report.ReportSections[0].Body.ReportItems[0].width = '3in';
    // report.ReportSections[0].Body.ReportItems[1].Top = '0.5in';
    // report.ReportSections[0].Body.ReportItems[1].Left = '3.5in';
    // report.ReportSections[0].Body.ReportItems[1].width = '3in';
  }
  let viewReport = "";
  let desinerobjectreport = "";
  async function onReportPreview() {
    setDesignerVisible(false);
    // let viewReport = await openReport();
    viewReport = await openReport();
    let rptdata = setReportJsondata();
    viewReport.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + rptdata;
    let token = JSON.parse(localStorage.getItem("token"));
    let TemplateId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    let ReportId = TemplateId;
    let mode = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    const postDataTemplate = { UserId, ReportId, mode };
    console.log("TemplateId 2", TemplateId);
    // const postData = { UserId, AcctId, ReportId };//{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (localStorage.getItem("WidgetSave") == 1) {
      mode = 3;
      const postDataTemplate = { UserId, ReportId, mode };
      await axios
        .post("/ActiverReportGet", postDataTemplate, config)
        .then((response) => {})
        .catch((error) => {});
      localStorage.setItem("WidgetSave", 0);
    } else {
      await axios
        .post("/ActiverReportGet", postDataTemplate, config)
        .then((response) => {
          let repdata = response.data;
          // let rptobject = repdata.activeReportOutput[0].rptObject;
          if (
            repdata.activeReportOutput.length > 0 &&
            repdata.activeReportOutput[0].reportId != 9999
          ) {
            let rptobject = repdata.activeReportOutput[0].rptObject;
            rptobject = JSON.parse(rptobject);
            setviewerReport(rptobject);
            desinerobjectreport = rptobject;
            rptobject.DataSources[0].ConnectionProperties.ConnectString =
              "jsondata=" + rptdata;
            viewReport = rptobject;
          }
          //     // else{
          //     //   viewerRef.current.Viewer.open(viewReport);
          //     // }
          // viewerRef.current.Viewer.open(viewReport);
        })
        .catch((error) => {});
    }
    // await axios.post('/ActiverReportGet', postDataTemplate, config)
    //   .then((response) => {
    //     let repdata = response.data;
    //     // let rptobject = repdata.activeReportOutput[0].rptObject;
    //     if (repdata.activeReportOutput.length > 0 && repdata.activeReportOutput[0].reportId != 9999) {
    //       let rptobject = repdata.activeReportOutput[0].rptObject;
    //       rptobject = JSON.parse(rptobject);
    //       setviewerReport(rptobject);
    //       desinerobjectreport = rptobject;
    //       rptobject.DataSources[0].ConnectionProperties.ConnectString =
    //         "jsondata=" + rptdata;
    //       viewReport = rptobject;
    //     }
    // //     // else{
    // //     //   viewerRef.current.Viewer.open(viewReport);
    // //     // }
    //     // viewerRef.current.Viewer.open(viewReport);
    //   }).catch((error) => { })
    // setLoadingViewer(false);
    let prDate = new Date(localStorage.getItem("processingDate"));
    let snapDate = new Date(localStorage.getItem("processingDate"));
    prDate.setMonth(prDate.getMonth() - 12);
    console.log(prDate);
    snapDate = `${(snapDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${snapDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${snapDate.getFullYear()}`;
    let parameters = [
      { Name: "pFromDate", Value: [prDate] },
      {
        Name: "pToDate",
        Value: [new Date(localStorage.getItem("processingDate"))],
      },
      { Name: "pPriceDate", Value: [localStorage.getItem("processingDate")] },
      { Name: "pAsofDate", Value: [localStorage.getItem("processingDate")] },
      { Name: "pSnapDate", Value: [snapDate] },
    ];
    viewerRef.current.Viewer.open(viewReport, { ReportParams: parameters });
  }
  async function OpenDesigner() {
    setDesignerVisible(true);
    let desingerReport = await openReport();
    //let desingerReport = desinerobjectreport;
    let token = JSON.parse(localStorage.getItem("token"));
    let TemplateId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    console.log("TemplateId 3", TemplateId);

    let ReportId = TemplateId;
    let mode = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    const postDataTemplate = { UserId, ReportId, mode };
    // const postData = { UserId, AcctId, ReportId };//{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    // let desingerReport="";
    await axios
      .post("/ActiverReportGet", postDataTemplate, config)
      .then((response) => {
        let repdata = response.data;
        if (
          repdata.activeReportOutput.length > 0 &&
          repdata.activeReportOutput[0].reportId != 9999
        ) {
          let rptobject = repdata.activeReportOutput[0].rptObject;
          rptobject = JSON.parse(rptobject);
          desingerReport = rptobject;
        }
        //     // else{
        //     //   designerRef.current.setReport({ definition: desingerReport, displayName: "my report", id: desingerReport.Name });
        //     // }
        //     designerRef.current.setReport({ definition: desingerReport, displayName: "my report", id: desingerReport.Name });
      })
      .catch((error) => {});
    designerRef.current.setReport({
      definition: desingerReport,
      displayName: "my report",
      id: desingerReport.Name,
    });
  }
  async function Reset() {
    setDesignerVisible(true);
    let desingerReport = await openReport();
    //let desingerReport = desinerobjectreport;
    let token = JSON.parse(localStorage.getItem("token"));
    let TemplateId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    console.log("TemplateId 4", TemplateId);

    let ReportId = TemplateId;
    let mode = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    const postDataTemplate = { UserId, ReportId, mode };
    // const postData = { UserId, AcctId, ReportId };//{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
    //   const config = {
    //     headers: {
    //       'authorization': `Bearer ${token.token}`,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   };
    //
    //  // let desingerReport="";
    //   await axios.post('/ActiverReportGet', postDataTemplate, config)
    //   .then((response) => {
    //     let repdata = response.data;
    //     // if (repdata.activeReportOutput.length > 0 && repdata.activeReportOutput[0].reportId != 9999) {
    //     //   let rptobject = repdata.activeReportOutput[0].rptObject;
    //     //   rptobject = JSON.parse(rptobject);
    //     //   desingerReport = rptobject;
    //     // }
    //     // else{
    //     //   designerRef.current.setReport({ definition: desingerReport, displayName: "my report", id: desingerReport.Name });
    //     // }
    //     designerRef.current.setReport({ definition: desingerReport, displayName: "my report", id: desingerReport.Name });
    //   }).catch((error) => { })
    designerRef.current.setReport(
      {
        definition: desingerReport,
        displayName: "my report",
        id: desingerReport.Name,
      },
      "",
      true
    );
  }
  function onSaveReport(info) {
    // const reportId = info.id;
    //const obj = new Object();
    //obj[reportId] = info.definition;
    let infodef = info.definition;
    // setReportStorage(infodef);
    importFile(infodef);
    //  var temp=JSON.stringify(new Map(reportStorage.set(reportId, info.definition)));
    return Promise.resolve({ displayName: info.displayName });
  }
  const importFile = async (infodef) => {
    //  const formData = new FormData();
    // formData.append("file", reportStorage);
    const RptObject = JSON.stringify(infodef);
    let mode = 2;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let ReportId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    console.log("TemplateId 5", ReportId);

    let token = JSON.parse(localStorage.getItem("token"));
    const postDataTemplateSave = { UserId, ReportId, RptObject, mode };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    // let postSaveData = {formData,repId};
    // setLoading(true);
    await axios
      .post("/ActiveReportSave", postDataTemplateSave, config)
      .then((response) => {
        console.log(response);
        // setLoading(false);
      })
      .catch((error) => {});
  };
  // designer changes new approach for custom rpt:start
  //designer changes new approach for custom rpt:end
  const showpreviewActive = () => {
    setShow(true);
    // openReport();
    onReportPreview();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };
  const handleClose = () => {
    reviverFunc(widgets, positions, 0);
    setShow(false);
    for (var i = 0; i < widgets.length; i++) {
      widgets[i].reorderable = true;
      widgets[i].resizable = true;
    }
  };
  const handleShow = () => setShow(true);
  const container = useRef(null);
  //   const exportPDFWithMethod = () => {
  //
  //     // document.getElementById("exitbutton").style.display = "none";
  //     let element = container.current || document.body;
  //     savePDF(element, {
  //       paperSize: "auto",
  //       margin: 40,
  //       fileName: `Report for ${new Date().getFullYear()}`,
  //     });
  //     //   document.getElementById("exitbutton").style.display = "inline";
  //   };
  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const [savingCheck, setSavingCheck] = useState(0);
  const initialPositions = [
    {
      widgetId: 1,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 0,
    },
    {
      widgetId: 2,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 1,
    },
    {
      widgetId: 3,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 2,
    },
    {
      widgetId: 4,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 3,
    },
    {
      widgetId: 5,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 4,
    },
    {
      widgetId: 6,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 5,
    },
    {
      widgetId: 7,
      col: 1,
      colSpan: 3,
      rowSpan: 1,
      order: 6,
    },
    {
      widgetId: 8,
      col: 4,
      colSpan: 3,
      rowSpan: 1,
      order: 7,
    },
    {
      widgetId: 9,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 8,
    },
    {
      widgetId: 10,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 9,
    },
    {
      widgetId: 11,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 10,
    },
    {
      widgetId: 12,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 11,
    },
    {
      widgetId: 13,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 12,
    },
    {
      widgetId: 14,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 13,
    },
    {
      widgetId: 15,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 15,
    },
    {
      widgetId: 16,
      col: 1,
      colSpan: 6,
      rowSpan: 36,
      order: 14,
    },
    {
      widgetId: 17,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 16,
    },
    {
      widgetId: 18,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 17,
    },
    {
      widgetId: 19,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 18,
    },
    {
      widgetId: 20,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 19,
    },
    {
      widgetId: 21,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 22,
    },
    {
      widgetId: 22,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 20,
    },
    {
      widgetId: 23,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 21,
    },
    {
      widgetId: 24,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 23,
    },
    {
      widgetId: 25,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 25,
    },
    {
      widgetId: 26,
      col: 4,
      colSpan: 3,
      rowSpan: 1,
      order: 24,
    },
    {
      widgetId: 27,
      col: 4,
      colSpan: 3,
      rowSpan: 9,
      order: 27,
    },
    {
      widgetId: 28,
      col: 1,
      colSpan: 3,
      rowSpan: 7,
      order: 26,
    },
    {
      widgetId: 29,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 28,
    },
    {
      widgetId: 30,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 29,
    },
    {
      widgetId: 31,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 32,
    },
    {
      widgetId: 32,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 30,
    },
    {
      widgetId: 33,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 31,
    },
    {
      widgetId: 34,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 32,
    },
    {
      widgetId: 35,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 34,
    },
    {
      widgetId: 36,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 36,
    },
    {
      widgetId: 37,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 35,
    },
    {
      widgetId: 38,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 37,
    },
    {
      widgetId: 39,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 38,
    },
    {
      widgetId: 40,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 39,
    },
    {
      widgetId: 41,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 40,
    },
    {
      widgetId: 42,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 41,
    },
    {
      widgetId: 43,
      col: 1,
      colSpan: 3,
      rowSpan: 4,
      order: 42,
    },
    {
      widgetId: 44,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 44,
    },
    {
      widgetId: 45,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 43,
    },
  ];
  const getPositions = (initialPositions) => {
    // Try to get positions from local storage
    // If we have none in the storage then default to initial positions
    return (
      JSON.parse(localStorage.getItem("dashboard-positions")) ||
      initialPositions
    );
  };
  // const getWidgets = (widgetsConfig) => {
  //   var temp=JSON.parse(localStorage.getItem("dashboard-widget"));
  //   var temp1=reviverFunc(temp);
  //   // Try to get positions from local storage
  //   // If we have none in the storage then default to initial positions
  //   return (
  //      temp1 || widgetsConfig
  //   );
  // };
  const widgetsConfig = [
    {
      id: 1,
      //header: "Cover Page",
      body: <CoverPageWiget />,
      active: true,
    },
    {
      id: 2,
      //header: "Table Of Content",
      body: <TableContentWiget />,
      active: true,
    },
    {
      id: 3,
      //header: "Introduction",
      body: <TextfieldWiget />,
      active: true,
    },
    {
      id: 4,
      //header: "Account Holding",
      body: <AcctHoldingWidget />,
      active: true,
    },
    {
      id: 5,
      //header: "Allocation Model",
      body: <AllocationmodelWidget />,
      active: true,
    },
    {
      id: 6,
      //header: "Asset vs Model chart",
      body: <AssetVsModelChartWidget />,
      active: true,
    },
    {
      id: 7,
      //header: "Asset vs Model data",
      body: <AssetvsModelDataWidget />,
      active: true,
    },
    {
      id: 8,
      //header: "Portfolio Holdings",
      body: <PortfolioHoldingWidget />,
      active: true,
    },
    {
      id: 9,
      //header: "Top Holdings",
      body: <TopHoldingsWidget />,
      active: true,
    },
    {
      id: 10,
      //header: "Conclusion",
      body: <TextBoxConclusion />,
      active: true,
    },
  ];
  const replacerFunc = (widgetsvar) => {
    if (widgetsvar) {
      var i = 0;
      for (i = 0; i < widgetsvar.length; i++) {
        widgetsvar[i].body = "";
      }
      return widgetsvar;
    } else {
      return null;
    }
  };
  const handleLock = () => {
    for (var i = 0; i < widgets.length; i++) {
      widgets[i].reorderable = false;
      widgets[i].resizable = false;
    }
  };
  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",
            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex">
            <div className="col text-start">
              <a className="mx-1">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end mx-3 py-1">
              {/* <h4 className='fw-bold text-fitek'  >{localStorage.getItem("tmpltNm")}</h4> */}
            </div>
          </div>
          <div className="row d-flex bg-fitek w-100 justify-content-around px-2 align-items-center py-1">
            <div className="col px-2">
              <h6 className="fw-bold text-white">
                Account : {selAcct.extrnlAcctId}
              </h6>
            </div>
            <div className="col px-2">
              <h6 className="fw-bold text-white">Date Created : 01/10//2023</h6>
            </div>
            <div className="col px-2">
              <h6 className="fw-bold text-white">
                Owner : {localStorage.getItem("owner")}
              </h6>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };
  const reviverFunc = (widgets, positions, param = 0) => {
    param = localStorage.getItem("Editable") === "1" ? 0 : 1;
    if (widgets) {
      var i = 0;
      var j = 0;
      for (i = 0; i < widgets.length; i++) {
        widgets[i].active = false;
        if (widgets[i].id === 1) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = <CoverPageWiget param={param} />;
          widgets[i].active = true;
        }
        if (widgets[i].id === 2) {
          //widgets[i].header=<><Button onClick={handleLock}>lock</Button>  Table Of Content</>;
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <TableContentWiget
                param={param}
                positions={positions}
                widgets={widgets}
              />
              <span className="page-break"></span>
            </p>
          );
          widgets[i].active = false;
        }
        if (widgets[i].id === 3) {
          widgets[i].active = true;
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <TextfieldWiget param={param} />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 4) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AccountProfileSmryWidget /> <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 5) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctHoldingWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 6) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AllocationmodelWidget />
              <span className="page-break"></span>
            </p>
          );
          widgets[i].active = false;
        }
        if (widgets[i].id === 7) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AssetVsModelChartWidget />
              <span className="page-break"></span>
            </p>
          );
          widgets[i].active = false;
        }
        if (widgets[i].id === 8) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AssetvsModelDataWidget />
              <span className="page-break"></span>
            </p>
          );
          widgets[i].active = false;
        }
        if (widgets[i].id === 9) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <TopHoldingsWidget />
              <span className="page-break"></span>
            </p>
          );
          widgets[i].active = false;
        }
        if (widgets[i].id === 10) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioHoldingWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 50) {
          widgets[i].active = true;
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = <TextBoxConclusion param={param} />;
        }
        if (widgets[i].id === 45) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = <DailyPerformanceWidgetGrid param={param} />;
        }
        if (widgets[i].id === 12) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctHoldingRptWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 13) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <CashflowWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 14) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctperfRORWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 15) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctperfSummaryWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 16) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctperfChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 17) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AstByMrktCapitalWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 11) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FixdIncmFundmntlsGrid />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 18) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PrchsSaleWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 19) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AssetAllocVsModelGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 20) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfoloiAllocatiionWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 21) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <ModelAllocationWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 22) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioVsModelWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 23) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <AcctTransactionGridWidget preview={show} />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 24) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <SectorComparisionGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 25) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <SectorComparisionChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 26) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortPerfSecurityGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 27) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioBreakdown1stGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 28) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioBreakdown2ndGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 29) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioBreakdown3rdGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 30) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioSnpshotGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 31) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioSnpshotCurrentChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 32) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioSnpshot2ndChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 33) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <PortfolioSnpshotBarChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 34) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FimMaturityDetails />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 35) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FimCallPutDetails />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 36) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FimMtrDtVsClPutdtl />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 41) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FipoBondQultySctr />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 42) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FipoMtrtyLadder />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 43) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FipoMnrIndusSec />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 44) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <FipoMjrIndusSec />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 37) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <SctrReturnPerfrmnceDataWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 38) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <SctrReturnPerfrmnceChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 39) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <TimeWtdRtnRptGridWidget />
              <span className="page-break"></span>
            </p>
          );
        }
        if (widgets[i].id === 40) {
          widgets[i].reorderable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].resizable =
            localStorage.getItem("Editable") === "1" ? true : false;
          widgets[i].body = (
            <p>
              <TimeWtdRtnRptChartWidget />
              <span className="page-break"></span>
            </p>
          );
        }
      }
      return widgets;
    } else {
      return null;
    }
  };
  //const [positions, setPositions] = useState(getPositions(initialPositions));
  const [widgets, setWidgets] = useState();
  const [dailyperfDAta, setdailyperfData] = useState();
  const [positions, setPositions] = useState();
  //const [widgets, setWidgets] = useState(widgetsConfig);
  const GetWidgetSettings = async () => {
    // setLoading(true);
    let Widgetlist = replacerFunc(widgets);
    let Pos = positions;
    let token = tempToken;
    let modUserId = JSON.parse(localStorage.getItem("userId"));
    let templateId = JSON.parse(localStorage.getItem("tmpltIdFrmDshBrd"));
    console.log("TemplateId 6", templateId);

    let coverPage =
      localStorage.getItem("txtCoverPage") === null
        ? ""
        : localStorage.getItem("txtCoverPage");
    let tableOfContent =
      localStorage.getItem("txtTableOfContent") === null
        ? ""
        : localStorage.getItem("txtTableOfContent");
    let introduction =
      localStorage.getItem("txtIntro") === null
        ? ""
        : localStorage.getItem("txtIntro");
    let conclusion =
      localStorage.getItem("txtConclusion") === null
        ? ""
        : localStorage.getItem("txtConclusion");
    const data = {
      templateId,
      modUserId,
      Widgetlist,
      Pos,
      coverPage,
      tableOfContent,
      introduction,
      conclusion,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/WidgetSettings", data, config)
      .then((response) => {
        console.log("WidgetSettings Response: ", response);
        //widgets=reviverFunc();
        reviverFunc(widgets, positions);
        //setLoading(false);
      })
      .catch((error) => {
        console.log("my error is " + error);
        if (error.response.status === 401) {
          refreshTokenWidget();
        }
      });
  };
  const handlesave = () => {
    localStorage.setItem("dashboard-positions", JSON.stringify(positions));
    let temp = widgets;
    localStorage.setItem(
      "dashboard-widget",
      JSON.stringify(replacerFunc(temp))
    );
    GetWidgetSettings();
    //reviverFunc(widgets,positions);
    // localStorage.setItem("dashboard-widget", JSON.stringify(widgets));
    //console.log(JSON.parse(localStorage.getItem("dashboard-widget")));
    // setWSaveFlag(true);
    localStorage.setItem("WidgetSave", 1);
  };
  const activeWidgets = useMemo(() => {
    if (usememocheck) {
      return widgets.reduce((acc, widget) => {
        // Bail out if widget is not active
        if (!widget.active) return acc;
        // Widget is active, so add it
        acc.push(widget);
        return acc;
      }, []);
    }
  }, [widgets]);
  // const filteredPositions = useMemo(() => {
  //   return positions.filter((position) => {
  //     // Find a matching widget using the id in the position id and return its active value
  //     return activeWidgets.find((widget) => widget.id === position.widgetId)
  //       ?.active;
  //   });
  // }, [activeWidgets, positions]);
  const handleReposition = (e) => {
    setPositions(e.value);
    //console.log(positions);
    //reviverFunc(widgets,e.value)
    //positions[9].order=20;
    for (var i = 0; i < widgets.length; i++) {
      if (widgets[i].id === 2) {
        //widgets[i].header=<><Button onClick={handleLock}>lock</Button>  Table Of Content</>;
        widgets[i].body = (
          <p>
            <TableContentWiget
              param={0}
              positions={e.value}
              widgets={widgets}
            />
            <span className="page-break"></span>
          </p>
        );
        break;
      }
    }
  };
  const onResetLayout = () => {
    setWidgets((widgets) =>
      widgets.map((widget) => ({ ...widget, active: true }))
    );
    setPositions(initialPositions);
  };
  const onToggleWidget = (e) => {
    const { id } = e.target.props;
    const { value } = e.target;
    const updatedWidgets = widgets.map((widget) => {
      if (widget.id === id) {
        return {
          ...widget,
          active: value,
        };
      }
      return widget;
    });
    //localStorage.setItem("widgetList",updatedWidgets);
    setWidgets(updatedWidgets);
    reviverFunc(updatedWidgets, positions);
  };
  const [dispOwner, setdispOwner] = useState("block");
  const [dispOwnerSwitch, setdispOwnerSwitch] = useState(true);
  const [dispInvofficer, setdispInvofficer] = useState("block");
  const [dispInvofficerSwitch, setdispInvofficerSwitch] = useState(true);
  const [dispAdmin, setdispAdmin] = useState("block");
  const [dispAdminSwitch, setdispAdminSwitch] = useState(true);
  const toggleDispOwner = () => {
    //console.log(dispOwner);
    if (dispOwner === "block") {
      setdispOwnerSwitch(false);
      setdispOwner("none");
    } else {
      setdispOwnerSwitch(true);
      setdispOwner("block");
    }
  };
  const toggleDispInvofficer = () => {
    if (dispInvofficer === "block") {
      setdispInvofficerSwitch(false);
      setdispInvofficer("none");
    } else {
      setdispInvofficerSwitch(true);
      setdispInvofficer("block");
    }
  };
  const toggleDispAdmin = () => {
    if (dispAdmin === "block") {
      setdispAdminSwitch(false);
      setdispAdmin("none");
    } else {
      setdispAdminSwitch(true);
      setdispAdmin("block");
    }
  };
  const exportPDFWithMethod = () => {
    let gridElement = document.getElementById("tile");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        console.log(dataUri.split(";base64,")[1]);
      });
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  //const [show, setShow] = React.useState(false);
  const handleCloseActive = () => setShow(false);
  if (loading) {
    // setLoading(false);
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <>
            <Header></Header>
            <Loading />
          </>
        )}
      </>
    );
  }
  return (
    <div>
      <Modal show={show} onHide={handleCloseActive} fullscreen={true} size="xl">
        <Modal.Body>
          {/* <div id="viewer-host">
            <Viewer ref={viewerRef}
            />
          </div> */}
          {/* <div id="designer-host">
            <Designer ref={designerRef} />
            </div> */}
          <>
            {localStorage.getItem("Editable") === "1" ? (
              !designerVisible ? (
                <>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => OpenDesigner()}
                  >
                    Open Designer
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => Reset()}
                  >
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {designerVisible ? (
              <div id="designer-host">
                <Designer
                  ref={designerRef}
                  onSave={onSaveReport}
                  onSaveAs={onSaveReport}
                  onRender={onReportPreview}
                ></Designer>
              </div>
            ) : (
              <>
                <div id="viewer-host">
                  <Viewer
                    onBack={(e) => {
                      setDesignerVisible(true);
                    }}
                    ref={viewerRef}
                  />
                </div>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary  btn-sm"
            onClick={handleCloseActive}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="sticky-top shadow-sm">
        <Header></Header>
      </div>
      <div className="row my-1 bg-light shadow-sm">
        {/* <div className="rounded"></div> */}
        <div className="d-flex justify-content-start align-items-center flex-wrap flex-md-nowrap pt-3 pb-2 mb-1">
          <div className="col my-2">
            <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
              {/* <ComboBox
  
               style={{
  
                 width: "350px",
  
               }}
  
               data={selAcctData}
  
               textField="extrnlAcctId"
  
               dataItemKey="acctId"
  
               filterable={true}
  
               value={selAcct}
  
               onChange={handleChange}
  
             // onFilterChange={filterChange}
  
             /> */}
              <div className="subheader h5">
                {localStorage.getItem("tmpltNm")}
              </div>
              <div className="col-sm-3 text-start fw-bold ">
                Account(s): {selAcct.extrnlAcctId}
              </div>
              <div className="col-sm-2 text-start">
                <div style={{ position: "relative", display: dispOwner }}>
                  <b>Owner: {localStorage.getItem("owner")}</b>
                </div>
              </div>
              <div className="col-sm-2 text-start">
                <div style={{ display: dispInvofficer }}>
                  <b> Inv.Officer: {localStorage.getItem("invOfficer")}</b>
                </div>
              </div>
              <div className="col-sm-3 text-start">
                <div style={{ display: dispAdmin }}>
                  <b>Admin.: {localStorage.getItem("administrator")}</b>
                </div>
              </div>
              <div className="col text-start">
                <div className="btn-toolbar mb-md-0 mx-2">
                  <div className="btn-group me-2">
                    {localStorage.getItem("Editable") === "1" ? (
                      <>
                        {/* <button title='Settings' type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                         <FaCogs size={20}></FaCogs>
                       </button> */}
                        <button
                          onClick={handlesave}
                          title="Save"
                          className="btn btn-sm btn-outline-secondary"
                        >
                          <FaSave size={20}></FaSave>{" "}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btn btn-sm btn-outline-primary "
                      title="Back"
                      onClick={(e) => {
                        navigate("/cstmDashBoardRpt");
                      }}
                    >
                      <IoMdArrowRoundBack size={20} />
                      Back
                    </button>
                    <button
                      className="btn btn-sm btn-outline-primary "
                      title="Export"
                      onClick={showpreviewActive}
                    >
                      Next
                      <IoMdArrowRoundForward size={20} />
                    </button>
                  </div>
                  {/* <div className='col-md-2 text-start'> */}
                  {/* <div className="dropdown"> */}
                  {/* <button className="btn btn-sm btn-outline-secondary " onClick={showpreviewActive} >
                      <ImFilePdf size={20}/>
                      </button> */}
                  {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"> */}
                  {/* <li><a className="dropdown-item" onClick={showpreviewActive} ><FaFilePdf></FaFilePdf> Pdf</a></li> */}
                  {/* <li><a className="dropdown-item" href="#"><FaFileExcel></FaFileExcel> Excel</a></li>
  
                   // <li><a className="dropdown-item" href="#"><FaFilePowerpoint></FaFilePowerpoint> PowerPoint</a></li> */}
                  {/* </ul> */}
                  {/* </div> */}
                  {/* </div> */}
                  <div>
                    {/* <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                         open ActiveReport
                       </a> */}
                    {/* <li><a onClick={showpreviewActive} > Pdf</a></li> */}
                    {/* <li><a className="dropdown-item" href="#"><FaFileExcel></FaFileExcel> Excel</a></li>
  
                   <li><a className="dropdown-item" href="#"><FaFilePowerpoint></FaFilePowerpoint> PowerPoint</a></li> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="widgetmodal"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="widgetmodal">
                      Widget Settings
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="my-2">
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={onResetLayout}
                      >
                        <FaSyncAlt></FaSyncAlt> Reset layout
                      </button>
                    </div>
                    <div className="m-2 p-1">
                      <h6 className="border-1 px-1 py-1">Toggle Widgets</h6>
                      <div style={{ overflowY: "scroll", height: "550px" }}>
                        {widgets.map((widget) => {
                          if (
                            widget.id !== 2 &&
                            widget.id !== 6 &&
                            widget.id !== 7 &&
                            widget.id !== 8 &&
                            widget.id !== 9
                          ) {
                            return (
                              <div className="my-2" key={widget.id}>
                                <Switch
                                  checked={widget.active}
                                  onChange={onToggleWidget}
                                  id={widget.id}
                                />
                                <label className="mx-2">{widget.header}</label>
                              </div>
                            );
                          }
                        })}
                        {/* <div className="my-2" >
  
                           <Switch checked={dispOwnerSwitch} onChange={toggleDispOwner}>Owner</Switch> <label className="mx-2">Owner</label>  </div>
  
                           <div className="my-2" >
  
                         <Switch checked={dispInvofficerSwitch} onChange={toggleDispInvofficer}></Switch><label className="mx-2">Investment Officer</label></div>
  
                         <div className="my-2" >
  
                         <Switch checked={dispAdminSwitch} onChange={toggleDispAdmin}>Owner</Switch><label className="mx-2">Admin</label></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TileLayout
          autoFlow="unset"
          style={{ position: "absolute", minWidth: "100%" }}
          columns={6}
          rowHeight={255}
          positions={positions}
          gap={{ rows: 10, columns: 10 }}
          items={activeWidgets}
          onReposition={handleReposition}
          className="tileLayout"
        />
      </div>
      {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
  
           <Modal.Header closeButton><div className="row w-100 tableheader">
  
             <div className='col'>
  
               <Modal.Title>Asset Allocation Vs Model Report - Pdf Preview</Modal.Title>
  
             </div>
  
             <div className='col text-end'>
  
               <button
  
                 className="btn btn-outline-primary btn-sm"
  
                 onClick={() => {
  
                   if (pdfExportComponent.current) {
  
                     pdfExportComponent.current.save();
  
                   }
  
                 }}
  
               >
  
                 Print
  
               </button>
  
             </div>
  
  
  
           </div>
  
           </Modal.Header>
  
           <Modal.Body>
  
           <PDFExport
  
         //forcePageBreak=".page-break"
  
         paperSize={'A4'}
  
       
  
       
  
         //keepTogether="div"
  
         landscape={true}
  
         ref={pdfExportComponent}
  
       >
  
       <div >
  
  
  
         <TileLayout
  
           reorderable={false}
  
           columns={3}
  
           rowHeight={4}
  
           positions={positions}
  
           gap={{ rows: 10, columns: 10 }}
  
           items={activeWidgets}
  
         
  
           className="tileLayout"
  
         />
  
  
  
       </div>
  
       </PDFExport>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
             </button>
  
           </Modal.Footer>
  
         </Modal> */}
    </div>
  );
};
//export default withSize({ refreshMode: "debounce", refreshRate: 60 })(CustomReportMain);
export default CustomReportMain;
export {
  dailyperfDatavar,
  snapshotCmprsncolorDatavar,
  sctrReturnPerfrmnceDatavar,
  sctrReturnPerfrmnceChartDatavar,
  TimeWtdRtnRptChartDatavar,
  TimeWtdRtnRptGridDatavar,
  portfoliholdingsDatavar,
  transactionDatavar,
  snapshotCmprsnDatavar,
  sectorCmprsnDatavar,
  portPerfBySecurityDatavar,
  portfolioBrkdwn1stDatavar,
  portfolioBrkdwn2ndDatavar,
  portfolioBrkdwn3rdDatavar,
  acctProfFinalDatavar,
  allocmodelDatavar,
  assetVsModelFinalDatavar,
  topHoldingDatavar,
  cashflowDatavar,
  astByMrktCapitalDatavar,
  accountHoldingDatavar,
  fixdincmfndmntlsDatavar,
  perfRORDatavar,
  perfMjrAsstSummaryDatavar,
  perfChartDatavar,
  purchaseAndSaleData,
  astVsModelData,
  acctPrflSmryDatavar,
  fimMaturityDeatils,
  fimMaturityLadrCallDetails,
  fimMatrtyDtVsCallPutDt,
  fipoBondQultySctr,
  fipoMaurityLadder,
  fipoBondByMinorCls,
  fipoBondByMjrIndstry,
};
