import React from "react";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaTachometerAlt,
  FaGem,
  FaList,
  FaGithub,
  FaRegLaughWink,
  FaHeart,
  FaDollarSign,
} from "react-icons/fa";
import { CgPerformance } from "react-icons/cg";
import { MdManageAccounts } from "react-icons/md";
import LogoPage from "./logoPage";
import {
  AiFillHome,
  AiOutlineSetting,
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
  AiFillContainer,
} from "react-icons/ai";
import { GoSignOut } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

import { color } from "@mui/system";
import { blue } from "@mui/material/colors";
import "./sidebar.css";
import { makeStyles } from "@mui/material";
import styled from "@emotion/styled";

const Sidebar = ({ rtl, toggled, handleToggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsedState, setcollapsedState] = React.useState(false);
  const headerStyle = {
    padding: "9px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 15,
    letterSpacing: "1px",
    color: "white",
    innerHeight: "10px",
  };
  /*const headerStyle1 = {
    height: 100vh,
  width: 300px;
  position: absolute;
  color: blue
  z-index: 200;
  };*/
  const openDashBoardPage = () => {
    navigate("/dashboard");
  };
  const openAcctHoldingRpt = () => {
    navigate("/acctHoldingRpt");
  };
  const openAcctTransactionRpt = () => {
    navigate("/acctTransactionRpt");
  };
  const openFixdIncmFndmntlsRpt = () => {
    navigate("/fixdIncmFndmntlsRpt");
  };
  const openPortfolioHoldingsRpt = () => {
    navigate("/portfoliHoldingsRpt");
  };
  const openProjectedCashFlowRpt = () => {
    
    navigate("/projectedCashFlowRpt");
  };
  const openAccountProfilePage = () => {
    navigate("/accountProfileReport");
  };
  const openFixdIncmMaturityLadderRpt = () => {
    navigate("/fixdIncmMtrtyLadrRpt");
  };
  const openAccountSectBenchRpt = () => {
    navigate("/AcctSectBenchRpt");
  };

  const openReportDesignerRpt = () => {
    navigate("/ReportDesignerRpt");
  };
  const openAcctPerfRpt = () => {
    navigate("/AcctPerfRpt");
  };
  const openFixedIncomePortfolioOverviewRpt = () => {
    navigate("/fixedIncomePortfolioOverviewRpt");
  };
  const openSectorReturnPerformanceRpt = () => {
    navigate("/sctrReturnPerfrmnceRpt");
  };
  const openTimeWtdRtnRpt = () => {
    navigate("/TimeWtdRtnRpt");
  };

  const openDailyPerformanceRpt = () => {
    navigate("/dailyPerformanceRpt");
  };
  const openPortfolioBreakDown = () => {
    navigate("/portfolioBreakDownRpt");
  };
  const openPerformanceWidget = () => {
    navigate("/performanceWidgetRpt");
  };
  // const openCustomRpt = () => {

  //   navigate("/cstmRptMain");
  // }
  const openAssetAllocModelRpt = () => {
    navigate("/assetAllocModelRptMain");
  };

  const openTopHoldingsRpt = () => {
    navigate("/topHoldingsReport");
  };
  const openAstByMrktCapitalRpt = () => {
    navigate("/astByMrktCapitalRpt");
  };
  const openPortPerfSecurityRpt = () => {
    navigate("/portPerfSecurityRpt");
  };
  const openPurchaseSaleRpt = () => {
    navigate("/prchsSaleRpt");
  };
  const openPrtflioSnpshtComprsnRpt = () => {
    navigate("/prtfolioSnpshtCmprsnRpt");
  };
  const openPortfolioOverviewRpt = () => {
    navigate("/portfolioOverviewRpt");
  };

  const openAnnualPortfolio = () => {
    navigate("/annualPortfolioReport");
  };
  const openCustomDashboard = () => {
    navigate("/cstmDashBoardRpt");
  };
  const openFxdIncmPrtfloOvrvw = () => {
    navigate("/fixedIncomePortfolioOverviewRpt");
  };
  const openActiveReport = () => {
    navigate("/customActiveReport");
  };
  const openAssetGrowthAndAllocationRpt = () => {
    navigate("/AssetGrowthAndAllocationRpt");
  };

  const openHistoricalMonthlyPerformance = () => {
    navigate("/historicalMonthlyPerformance");
  };

  const openPerformanceRiskmetricsRpt = () => {
    navigate("/PerformanceRiskMetricsRpt");
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        // 
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const collapsetoggle = () => {
    if (collapsedState) setcollapsedState(false);
    else setcollapsedState(true);
  };

  const Menuitem = styled(MenuItem)`
    :hover {
      // background-color: #335B8C !important;
      color: white !important;
      border-radius: 8px !important;
      font-weight: bold !important;
    }
  `;
  return (
    <div id="sidebar">
      <ProSidebar
        style={{ borderColor: "black" }}
        collapsed={collapsedState}
        width="auto"
      >
        <div className="sidebarstyle">
          <SidebarHeader>
            <Menu iconShape="circle">
              {collapsedState ? (
                <Menuitem
                  icon={<AiOutlineDoubleRight size={20} />}
                  onClick={collapsetoggle}
                ></Menuitem>
              ) : (
                <Menuitem
                  suffix={<AiOutlineDoubleLeft size={20} />}
                  onClick={collapsetoggle}
                ></Menuitem>
              )}
            </Menu>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="circle">
              <Menuitem
                icon={<AiFillHome size={20} />}
                onClick={openCustomDashboard}
              >
                Dashboard
              </Menuitem>
              {/* <hr></hr> */}
              {/* <Menuitem icon={<AiFillContainer size={20}/>}  onClick={openCustomDashboard}>Custom Dashboard</Menuitem>
               */}

              <hr></hr>
              <SubMenu title="Account" icon={<MdManageAccounts size={20} />}>
                <Menuitem width="auto" onClick={openAccountProfilePage}>
                  Profile
                </Menuitem>
                <Menuitem onClick={openAcctTransactionRpt}>
                  Transactions
                </Menuitem>
                <Menuitem onClick={openAcctHoldingRpt}>Holdings</Menuitem>

                <Menuitem onClick={openAccountSectBenchRpt}>
                  Sectors Comparison
                </Menuitem>
                {/* <Menuitem onClick={openAcctPerfRpt}>Performance</Menuitem> */}

                <Menuitem onClick={openPortfolioHoldingsRpt}>
                  Portfolio Holdings
                </Menuitem>

                <Menuitem onClick={openAstByMrktCapitalRpt}>
                  Assets by Market Capital{" "}
                </Menuitem>
                <Menuitem onClick={openPortPerfSecurityRpt}>
                  Portfolio Performance By Security{" "}
                </Menuitem>

                <Menuitem onClick={openProjectedCashFlowRpt}>
                  Cash Flow{" "}
                </Menuitem>
                <Menuitem onClick={openPortfolioBreakDown}>
                  Portfolio BreakDown
                </Menuitem>
                {/* <Menuitem onClick={openPerformanceWidget}>Performance Widget</Menuitem>
                 */}
                <Menuitem onClick={openAssetAllocModelRpt}>
                  Asset Allocation Vs Model
                </Menuitem>
                <Menuitem onClick={openTopHoldingsRpt}>Top Holdings</Menuitem>

                <Menuitem onClick={openPurchaseSaleRpt}>
                  Purchase & Sell{" "}
                </Menuitem>

                <Menuitem onClick={openPrtflioSnpshtComprsnRpt}>
                  Portfolio Snapshot Comparison
                </Menuitem>

                <Menuitem onClick={openPortfolioOverviewRpt}>
                  Portfolio Overview
                </Menuitem>
              </SubMenu>

              <SubMenu title="Fixed Income" icon={<FaDollarSign size={20} />}>
                <Menuitem onClick={openFixdIncmMaturityLadderRpt}>
                  Maturity Ladder
                </Menuitem>
                <Menuitem onClick={openFixdIncmFndmntlsRpt}>
                  Fundamentals
                </Menuitem>
                <Menuitem onClick={openFxdIncmPrtfloOvrvw}>
                  Portfolio Overview
                </Menuitem>
              </SubMenu>
              <SubMenu title={"Performance"} icon={<CgPerformance size={20} />}>
                <Menuitem onClick={openSectorReturnPerformanceRpt}>
                  Sector Return
                </Menuitem>
                <Menuitem onClick={openAcctPerfRpt}>
                  Account Performance Summary{" "}
                </Menuitem>
                <Menuitem onClick={openTimeWtdRtnRpt}>
                  Time Weighted Return
                </Menuitem>
                <Menuitem onClick={openDailyPerformanceRpt}>
                  Historical Market Performance
                </Menuitem>
                <Menuitem onClick={openAssetGrowthAndAllocationRpt}>
                  Asset Growth and Allocation
                </Menuitem>
                <Menuitem onClick={openAnnualPortfolio}>
                  Annual Portfolio
                </Menuitem>
                <Menuitem onClick={openHistoricalMonthlyPerformance}>
                  Historical Monthly Performance
                </Menuitem>
                <Menuitem onClick={openPerformanceRiskmetricsRpt}>
                  Performance Risk Metrics
                </Menuitem>
              </SubMenu>
              {/* <SubMenu title="Custom Report" icon={<AiFillCodepenSquare size={20}/>} onClick={openCustomRpt}></SubMenu> */}
              {/* <SubMenu title="Settings" icon={<AiOutlineSetting size={20} />}></SubMenu> */}

              {/* <Menuitem onClick={signOut} icon={<GoSignOut size={20}/>}>Logout</Menuitem> */}
              <Menuitem></Menuitem>
            </Menu>
          </SidebarContent>
          <SidebarFooter className="my-3 py-2"></SidebarFooter>
        </div>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
