import React from 'react'
import { useState,useEffect } from 'react'
import axios from 'axios';
import { formatNumber, formatDate,parseNumber } from '@telerik/kendo-intl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { filterBy } from '@progress/kendo-data-query';

import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from './loading';
import Header from './header';
import AccountProfileDetails from './accountProfileDetails';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import AnnualPortfolioValueDetails from './annualPortfolioValueDetails';

const AnnualPortfolioValueRpt = () => {
    const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
    const [chartColor, setChartColor] = useState([]);
    
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selectedAcct, setSelectedAcct] = useState(0);
    const [loading, setLoading] = useState(true);
    const [annualPortfolioData, setAnnualPortfolioData] = useState([]);
    const [modelId, setModelId] = useState(-1);
    const [flag, setFlag] = useState(0);
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);

    const[combinedData, setCombinedData] = useState([])

    const navigate = useNavigate();

    var tempToken = JSON.parse(localStorage.getItem('token'));


    useEffect (() => {

        const fetchData = async () => {
            setLoading(true);
            setEnableCombo(true);
            try {
                getAnnualPortfolioInfo();
            } catch (error) {
                console.error(error.message);
            }

        }
        fetchData();
    }, []);


    const getAnnualPortfolioInfo = async () => {

        setLoading(true);
        setEnableCombo(true);

       // let token = JSON.parse(localStorage.getItem('token'));
        let token = tempToken;
        let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
        setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
        let ConsolidationId = 0;
        let AsOfId =JSON.parse(localStorage.getItem('userId'));

      
        const postData1 = { AsOfId, AcctId, ConsolidationId  };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/RTAnnualPortfolio',
            postData1,
            config
        ).then((response => {

            // console.log("Response", response)
            // console.log("got response")
           
            const rowData = response.data;
            setAssetAllocModelRptData(rowData);

            console.log("Row Data", rowData)
           
            // for(var i=0;i<rowData.t2.length;i++)
            //     {


            //     }

            // const combinedArray = rowData.t2.concat(rowData.t3);

            // console.log("combinedArray", combinedArray);
            // setCombinedData(combinedArray)

            // console.log("got response 1")
            
            // localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
            // console.log("got response 2")
            setAnnualPortfolioData(response.data.model);
            // console.log("got response 3")
            setModelId(JSON.parse(localStorage.getItem("modelId")));
            // console.log("got response 4")
            setLoading(false);
            setEnableCombo(false);
            // console.log("Afterv Response Loading",loading);
        })
        )

            .catch((error) => {

                if (error.response.status === 401) {
                    refreshToken();
                }
            });
       
    }

    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {
               
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));

                getAnnualPortfolioInfo();


            })
            .catch((error) => {
                if(error.code==="ERR_BAD_REQUEST"){
                    setSession("Session Expired");
                  }
                // console.log("my error is " + error);
            })

    }

    // const GetAllocModelData = async () => {
       
    //     setLoading(true);


    //     let token = tempToken;
    //     let UserId = JSON.parse(localStorage.getItem('userId'));
    //     // 
    //     let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    //     let AcctId = lsSelectedAcct.acctId;
    //     let mAccountId = AcctId;
    //     let NumOfRows = 10;
    //     let PageId = 1;
    //     let modelId = JSON.parse(localStorage.getItem("modelId"));
    //     let invMix = 0;
    //     if(modelId==-1)
    //     invMix=1;
    //     // if (lsSelectedAcct !== null) {
    //     //     AcctId = lsSelectedAcct.acctId;
    //     // }
    //     let AsOfId = JSON.parse(localStorage.getItem('userId'));

    //     const postData = { AsOfId, AcctId, PageId,invMix };
    //     // const postData = { UserId, mAccountId};
    //     const config = {
    //         headers: {
    //             'authorization': `Bearer ${token.token}`,
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }

    //     };
    //     await axios.post('/RTGetAnnualPortfolio',
    //         postData,
    //         config
    //     )
    //         .then(response => {
    //           // 
    //               console.log("MainResponse", response);
    //           // 
    //             const rowData = response.data;
    //             for(let i=0;i<rowData.ocrtAccountProfile.length;i++)
    //                 {
    //                     rowData.ocrtAccountProfile[i].txCstAmt=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].txCstAmt, "##,#.00"));
    //                     rowData.ocrtAccountProfile[i].market=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].market,"##,#.00"));
    //                     rowData.ocrtAccountProfile[i].income=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].income,"##,#.00"));
    //                     rowData.ocrtAccountProfile[i].yield=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].yield,"##,#.00"));
    //                     rowData.ocrtAccountProfile[i].marketPercent=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].marketPercent,"##,#.00"));
    //                 }
    //             setAssetAllocModelRptData(rowData.ocrtAccountProfile);
    //             setChartColor(rowData.ocAssetColorModel);
             
    //             if (lsSelectedAcct !== null) {
    //                 SetselAcct(lsSelectedAcct);
    //             }
    //             else {
    //                 //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
    //             }
    //             setLoading(false);
    //             setFlag(1);
    //         })
    //         .catch((error) => {
             
    //             if (error.response.status === 401 )

    //             {
    //               refreshToken();


    //             }
    //             return error;
    //         });

    // }

    const filterData = (filter) => {
            return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
       };
    
    const handleChange = (event) => {
        
        if (event.target.value === null ) {
            // console.log("Hii I am here");
            SetselAcct(selAcct);
             localStorage.setItem('IsAcctSelected', false);
             localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
        }
        else {
            // console.log("Hii I am here section 2");
            SetselAcct(event.target.value);
            localStorage.setItem('IsAcctSelected', true);
            localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
            getAnnualPortfolioInfo();
        }



    };

         const filterChange = (event) => {
     
            setSelAcctData(filterData(event.filter));
          };

    const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // 
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
    
    
            // }
    
           
          })
          .catch((error) => {
            // 
            // console.log("my error is " + error);
          })
    
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }


      if (loading) {
        return (
          <>
          {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
          
            <Header />
            <div className='my-1'>
                <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        {console.log("selctacct1",selAcct)}
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            onFilterChange={filterChange}
                            disabled={enableCombo}
                        />
                    </div>
                </div>
            </div>
            <Loading />
         
          </div>}</>
        )
      }
    return (
        <div style={{overflow:"hidden"}}>
            <Header />
            <div className='my-1'>
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        {console.log("selctacct",selAcct)}

                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            onFilterChange={filterChange}
                            disabled={enableCombo}
                        />
                    </div>
                </div>
            </div>
            
            {/* <AccountProfileDetails chartColor={chartColor} data={assetAllocModelRptData} acct={selAcct} allannualPortfolioData={annualPortfolioData} selModelId={modelId} /> */}
            <AnnualPortfolioValueDetails data={assetAllocModelRptData} />

            
        </div>
    )
}

export default AnnualPortfolioValueRpt
